export const activateProduct = {

    productID: {
        presence: true,
    },

    active: {
        presence: true,
    }

}


export const linkAudiance = {
    productID: {
        presence: true,
    },

    audianceID: {
        presence: true
    }
}

export const delinkAudiance = {
    productID: {
        presence: true,
    },

    audianceID: {
        presence: true
    }
}