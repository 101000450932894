import React from 'react';
import styled from 'styled-components';

// -----------
// CREATE STYLED COMPONENTS


const QuestionMark = ({ message, sizeType, color }) => {

        return (
            <Container type={sizeType}>
                <Mark type={sizeType} backgroundColor={color}>?</Mark>
                <Message type={sizeType}>
                    <Contents>
                        <ContentsArrow />
                        <ContentsText>{ message }</ContentsText>
                    </Contents>
                </Message>
            </Container>
        );
}

export default QuestionMark;



const Message = styled.div`
    position:absolute;
    height:50px;
    width: ${props => props.type === 'small' ? '250px': '200px' };
    display:none;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    z-index:20000;
`

const Container = styled.div`
    padding-left:${props => props.type === 'small' ? 2 : 10 }px;
    margin-bottom:${props => props.type === 'small' ? 0 : 2 }px;
    cursor:pointer;

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;

    &:hover > ${Message} {
        display:flex;
    }

`

const Mark = styled.div`
    border-radius:100px;
    height:${props => props.type === 'small' ? 12 : 17 }px;
    width:${props => props.type === 'small' ? 12 : 17 }px;
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    font-size:${props => props.type === 'small' ? 10 : 12 }px;
    justify-content:center;
    align-content:center;
    align-items:center;
    background:${props => props.backgroundColor};
    color:#ffffff;
    position:relative;
    


`




const Contents = styled.div`
    position:relative;
    width:100%;
    left:55%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-content:center;
    align-items:center;
`

const ContentsText = styled.div`
    display:flex;   
    padding:10px;
    background:#516670;
    color:#ffffff;
    font-size:13px;
    text-align:left;
    border-radius:4px;
`

const ContentsArrow = styled.div`
    display:flex;
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right: 12px solid #516670;
    margin-right:-2px;
`