module.exports = {


    // CLIENT SIDE ERRORS -- START WITH 1XX
    // ------------------------------------

    INCOMPLETE_FORM: {
        code: 101,
        description: 'Form is not completed'
    },

    
    EMAIL_IN_USE: {
        code: 103,
        description: 'Email already associated with an account. You can sign in.'
    },


    // SERVER SIDE ERRORS - START WITH 9XX
    // ------------------------------------

    CONNECTION_TO_API_NOT_WORKING: {
        code: 901,
        description: 'Unable to make a request to the server. Try again in a few minutes.'
    },

    INCORRECT_CREDENTIALS: {
        code: 902,
        description: 'Either your email or password is incorrect. Try again.'
    },
    
}
