import axios from 'axios';
import { JSONResponseToConsole, authHeader, signout } from '../index';

import {
    AUTH_ERROR
}  from '../types';

import { 
    CONNECTION_TO_API_NOT_WORKING,
    INCORRECT_CREDENTIALS
} from '../errors';

import { 
    ROUTE_WEDDING_GETINFO,
    ROUTE_WEDDING_UPDATESTAY,
    ROUTE_WEDDING_UPDATEFLIGHT
} from '../routes';


const handleServerErrors = (dispatch, callback, e) => {


    let message = '';

    if (e.response && e.response.status === 401) {
        message = INCORRECT_CREDENTIALS.description;
    } else {
        message = CONNECTION_TO_API_NOT_WORKING.description
    }

    dispatch({ type: AUTH_ERROR, payload: message });
    callback(false, message, true);

}

export const getInfo = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_WEDDING_GETINFO, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_WEDDING_GETINFO} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }

}


export const updateStayDetail = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_WEDDING_UPDATESTAY, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_WEDDING_UPDATESTAY} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }

}



export const updateFlightDetail = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_WEDDING_UPDATEFLIGHT, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_WEDDING_UPDATEFLIGHT} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }

}




