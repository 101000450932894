import React from 'react';
import styled from 'styled-components';


const BenefitsReel = ({ data }) => {

    
    return (

        <Container addSidePadding={true} shadow={false}>

            {
                data.map((item, index) => {

                    return (
                        <SectionThird key={index}>
                            <BenefitContents>
                                <BenefitTitle>{item.title}</BenefitTitle>
                                <BenefitImage>
                                    <BenefitImages src={item.image} /> 
                                </BenefitImage>
                                <BenefitDescription>{item.description}</BenefitDescription>
                            </BenefitContents>
                        </SectionThird>
                    )
                })
            }
        </Container>

    );
}


export default BenefitsReel;


const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    padding-bottom:50px;
    min-width:500px;
    padding-left:${props => props.addSidePadding ? 50 : 0 }px;
    padding-right:${props => props.addSidePadding ? 50 : 0 }px;
    ${props => {
        if (props.shadow === 'below') {
            return (`box-shadow: rgba(249, 249, 249, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;`)
        } else if (props.shadow === 'above') {
            return (`box-shadow: rgba(249, 249, 249, 0.12) 0px -9px 14px 0px, rgba(0, 0, 0, 0.12) 0px -5px 6px 0px;`)
        }
    }}
    

`


const SectionThird = styled.div`
    flex-grow:1;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    flex-wrap:nowrap;
    align-items:center;
    align-content:center;
    height:35vh;
    min-width: 200px;

`



const BenefitContents = styled.div`
    border-radius:10px;
    display:flex;
    width:90%;
    height:inherit;
    flex-direction:column;
    justify-content:center;
    flex-wrap:nowrap;
    align-items:center;
    align-content:center;
    background:#ffffff;
    cursor:pointer;
    transition:all 0.5s;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 0px 5px 0px, rgba(0, 0, 0, 0.06) 0px 0px 5px 0px; 

    &:hover {
        box-shadow: rgba(60, 66, 87, 0.12) 0px 0px 5px 0px, rgba(0, 0, 0, 0.12) 0px 0px 5px 0px;
        margin-top:-10px;
    }
`



const BenefitTitle = styled.div`
    font-family: newFontFace, Arial, sans-serif;
    width:100%;
    height:80px;
    box-sizing:border-box;
    font-size:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    text-align:center;
`




const BenefitImages = styled.img`
    display:flex;
    object-fill:contain;
    box-sizing:border-box;
    height:100px;
    padding:0;
    margin:0;
`


const BenefitImage = styled.div`
    padding:10px;
    width:80%;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
`

const BenefitDescription = styled.div`
    padding:10px;   
    width:80%;
    display:flex;
    font-family: newFontFaceLight, Arial, sans-serif;
    justify-content:center;
    align-items:center;
    align-content:center;
    text-align:center;
`      