import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import defaults from '../../constants/defaults';

import Redirections from '../../constants/routes';

import { icons, elements } from '../../components';

import * as screens from '../../screens';


const Account = (props) => {

    const { sectionName } = props.match.params;

    // General
    const [pageReady, setpageReady] = useState(true);  

    // Helpers 

    useEffect(() => {
        
        const pagesToAllow = ['general', 'billing', 'privacy']

        if (!pagesToAllow.includes(sectionName)) {
            return props.history.push(`/account/general`);
        }
       
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionName])


    useEffect( () => {
        setpageReady(true);
    }, []) 


    return (
        <elements.PageContainer 
            pageReady={pageReady}
            pageTitle={defaults.tabbar.GENERAL}
            pageRef={`account`}
            sticky={false}
            cover={false}
            menu={'access'}
            topPadding={`60px`}
        >

                <SectionNext>
                    <ConversationList>
                        <Conversations>
                            <InternalTextTop>Account Settings</InternalTextTop>
                            <EachItem to={Redirections.ROUTE_LINK_ACCOUNT_GENERAL.partial} active={ `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_GENERAL.partial ? 'true' : 'false'}>
                                <icons.PersonStyled active={ `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_GENERAL.partial ? 'true' : 'false'} />
                                <InternalText active={ `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_GENERAL.partial ? 'true' : 'false'}>General</InternalText>
                            </EachItem>
                            <EachItem to={Redirections.ROUTE_LINK_ACCOUNT_BILLING.partial} active={ `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_BILLING.partial ? 'true' : 'false'}>
                                <icons.CreditCardRoundedStyled active={ `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_BILLING.partial ? 'true' : 'false'} /> 
                                <InternalText active={ `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_BILLING.partial ? 'true' : 'false'}>Account and billing</InternalText>
                            </EachItem>
                            <EachItem to={Redirections.ROUTE_LINK_ACCOUNT_PRIVACY.partial} active={ `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_PRIVACY.partial ? 'true' : 'false'}>
                                <icons.SecurityRoundedStyled active={ `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_PRIVACY.partial ? 'true' : 'false'} />
                                <InternalText active={ `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_PRIVACY.partial ? 'true' : 'false'}>Privacy and security</InternalText>
                            </EachItem>
                        </Conversations>
                    </ConversationList>

                    <MessagePanel>
                        <ConversationPanel>

                            {
                                // General
                                `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_GENERAL.partial ? (
                                    <screens.AccountGeneral />
                                ) : null
                            }

                            {
                                // Account and billing
                                `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_BILLING.partial ? (
                                    <screens.AccountBilling />
                                ) : null
                            }

                            {
                                // Privacy and security
                                `/account/${sectionName}` === Redirections.ROUTE_LINK_ACCOUNT_PRIVACY.partial ? (
                                    <screens.AccountPrivacy />
                                ) : null
                            }
  
                        </ConversationPanel>
                    </MessagePanel>
                </SectionNext>

        </elements.PageContainer>
    )

}

export default Account;



const ConversationPanel = styled.div`
    z-index:1000;
    overflow:scroll;
    display:flex;
    width:100%;
    height:inherit;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    flex-wrap:nowrap;
    background:#ffffff;
    transition:all 0.5s;
    padding-top:20px;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 0px 5px 0px, rgba(0, 0, 0, 0.06) 0px 0px 5px 0px; 
`


const Conversations = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:100%;
    overflow:scroll;
    padding:30px;
`

const InternalText = styled.div`
    font-size:13px;
    color: ${props => props.active === 'true' ? '#ffffff' : '#000000'};
    padding-left:10px;
    transition: all 0.2s;
`





const EachItem = styled(Link)`
    color: ${props => props.active === 'true' ? '#ffffff' : '#000000'};
    box-sizing:border-box;
    font-size:13px;
    padding: 15px;
    outline:0;
    margin-top:10px;
    cursor:pointer;
    transition: all 0.3s;
    text-decoration:none;
    width:100%;
    background: ${props => props.active === 'true' ? '#5D8AA8' : 'transparent'}; 
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    font-weight:bold;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    
    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        background: #bedfe5;

        & > ${InternalText} {
            color: #000000
        }

        & > ${icons.CreditCardRoundedStyled} {
            fill: #000000
        }

        & > ${icons.PersonStyled} {
            fill: #000000
        }

        & > ${icons.SecurityRoundedStyled} {
            fill: #000000
        }

    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
        background: '#5D8AA8'
    }

`

const InternalTextTop = styled.div`
    font-size:14px;
    color: #a9a9a9;
    padding:20px;
    text-align:center;
    width:100%;

`



const MessagePanel = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:100%;
    z-index:1000;
    padding-right:50px;
`

const ConversationList = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:350px;
    height:100%;
    z-index:1005;
`




const SectionNext = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
`
