import React from 'react';
import styled from 'styled-components';

import { icons } from '../..';

const PasswordToEnter = ({ 
    title, 
    placeholder, 
    errorMessage,
    ready, 
    type, 
    bind, 
    setWidth,
    additionalStyling, 
    value, 
    onBlur, 
    typeOf, 
    maxChar,
    fromRight,
    symbol: Symbol,
    tabIndex,
    symbolRight: SymbolRight }) => {

    if (!onBlur) {
        onBlur = null;
    }

    if (!maxChar) {
        maxChar = null;
    }

    const finalErrorMessage = errorMessage ? errorMessage : []

    return (

        <>
        <Container setWidth={setWidth} additionalStyling={additionalStyling} errorFormating={finalErrorMessage.length > 0}>

                {
                    !!Symbol && (
                        <ShowSymbol><Symbol /></ShowSymbol>
                    )
                }

                <InputComponent fromRight={!!fromRight} symbol={!!Symbol} typeOf={typeOf} placeholder={placeholder} readOnly={!ready} type={type} tabIndex={tabIndex} {...bind}/>

                <CharCounter aboveMax={!!maxChar && (value ? (value.length > maxChar) : false)} >{(!!maxChar && (value ? (value.length > maxChar) : false)) ? `Exceeding maximum characters` : `` } { (value && !!maxChar) ? (value.length > 1 ? value.length : ``) : `` }{ !!maxChar ? ` / ${maxChar}` : ``} </CharCounter>
                
                {
                    !!SymbolRight || finalErrorMessage.length > 0 ? (
                        <ShowSymbolRight>
                           
                           { !!SymbolRight && <SymbolRight /> }
                           
                           { finalErrorMessage.length > 0 && <icons.ErrorSymbol /> }

                        </ShowSymbolRight>
                    ) : null
                }

        </Container>

        { finalErrorMessage.length > 0 ? (
            <ErrorFieldOuter>
                <ErrorField>

                    { 
                        finalErrorMessage.map((item) => (
                            <li>{ item }</li>
                        ))
                    }
    
                </ErrorField>
            </ErrorFieldOuter>
        ) : null }
        </>

    )

}

export default PasswordToEnter;


const Container = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    position:relative;
    border-radius:5px;
    width:${props => !props.setWidth ? `100%` : props.setWidth};

    background:transparent;
    border:none;

    ${props => props.additionalStyling}
    ${props => props.errorFormating ? (`
        background: #FFF2F2;
        border: 1px solid #FF7F7F;
    `) : null }
`


const ErrorFieldOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
`

const ErrorField = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:10px;
    font-size:14px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    list-style:none;

    & > li {
        color: red;
        margin-left:5px;

    }
`



const InputComponent = styled.input`
    width:100%;
    height:100%;
    border:none;
    outline:none;
    border-radius:5px;
    padding:15px 15px;
    ${props => props.symbol ? `padding-left:50px` : null};
    ${props => props.fromRight ? `text-align:right` : `text-align:left`};
    font-size:16px;
    z-index:1010;
    background:none;
    outline:none;
    text-align:center;
`

const CharCounter = styled.div`
    position:absolute;
    right:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    ${props => props.aboveMax ? `color: red` : null};
`

const ShowSymbol = styled.div`
    position:absolute;
    left:15px;
    display:flex;
    align-items:center;
    justify-content:center;
`

const ShowSymbolRight = styled.div`
    position:absolute;
    flex-direction:row;
    right:15px;
    display:flex;
    align-items:center;
    justify-content:center;
`