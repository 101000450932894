// EXTERNAL: All
// -------------------------------

import React  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import defaults from './constants/defaults';
import pages from './navigation/pages';


// INTERNAL: Foundational
// -------------------------------

import reportWebVitals from './reportWebVitals';
import { authenticate } from './services';
import { store } from './state/store';
import './constants/index.css';
import App from './App';

import { History } from './services'


// Establish browswer routing information
// -------------------------------

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

if (path) {
    History.history.replace(path);
}


// PRIMARY RENDER
// -------------------------------

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <App>
              <Switch>
                
                  {
                    
                    // Populate pages from pages.js

                    pages.map(({ PageType, pagePath, pageComponent }) => {
                      return <PageType key={pagePath} path={pagePath} exact component={pageComponent} />
                    })

                  }

                  <authenticate.NoPath />

              </Switch>
            </App>
        </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


// --------------------------------------------------------
// --
// -- CONTEXT TOOLING
// --
// --------------------------------------------------------

if (process.env.NODE_ENV === 'development') {
  console.log(defaults.console.START_MESSAGE_DEV);
} else {
  console.log(defaults.console.START_MESSAGE_PROD);
  // reportWebVitals(console.log);
}