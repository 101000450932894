// -------------------------------
// TEXTUAL EXPORTS

// Display to the console
module.exports.console = {
  START_MESSAGE_DEV: `Rachel & Dylan Development Environment`,
  START_MESSAGE_PROD: `RachelAndDylan.com © Dylan Carter`
}


// Display to the console
module.exports.tabbar = {
  GENERAL: `Wedding of Rachel & Dylan`
}

// Throughout application
module.exports.app = {
  TITLE_LOGIN: `Log In`,
  TITLE_JOIN: `Join`
}
