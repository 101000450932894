const numberWithCommas = (x, currency, currencyCode) => {

    x = Math.floor(x * 100) / 100;

    return currency + x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + currencyCode;
}

const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
}


const format = { numberWithCommas, toTitleCase };

export default format;
