import { 
    AUTH_USER, 
    AUTH_ERROR,
    CREATE_CARD,
    LOAD_DISPLAY_PICTURE
} from '../actions/types';

const INITIAL_STATE = {
    authenticated: '',
    errorMessage: '',
    name: '',
    clientSecret: '',
    displayPicture: ''
}

const reducer = (state = INITIAL_STATE, action) => {

    switch(action.type) {

        case AUTH_USER:
            return { ...state, 
                    name: action.payload.name, 
                    authenticated: action.payload.token,
                    displayPicture: action.payload.displayPicture
                 };
        
        case AUTH_ERROR:
            return { ...state, errorMessage: action.payload };
        
        case CREATE_CARD:
            return { ...state, clientSecret: action.payload };

        case LOAD_DISPLAY_PICTURE:
            return { ...state, displayPicture: action.payload };

        default:
            return state;

    }
}

export default reducer;