import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Redirections from '../../constants/routes';


// Accessible only if logged in

const PrivateRoute = ({component: Component, ...rest }) => {

    const auth = !!useSelector(state => state.auth.authenticated);

    return (
      <Route 
        {...rest}
        render={(props) => auth === true 
          ? <Component {...props} />
          : <Redirect to={{pathname: Redirections.ROUTE_LINK_LOGIN.partial, state: { from: props.location }}} />}
      />
    )

}

// Accessible only if logged out

const PublicRoute = ({component: Component, ...rest }) => {

  const auth = !!useSelector(state => state.auth.authenticated);

  return (
    <Route 
      {...rest}
      render={(props) => auth === false 
        ? <Component {...props}  />
        : <Redirect to={{pathname: Redirections.ROUTE_LINK_HOME_INTERNAL.partial, state: { from: props.location }}} />}
    />
  )

}

// Accessible if logged in or logged out and provides a truey/falsy on the 'auth' prop if helpful.

const OpenRoute = ({component: Component, ...rest }) => {

    const auth = !!useSelector(state => state.auth.authenticated);

    return (
      <Route 
        {...rest}
        render={(props) => <Component {...props} {...auth}  />}
      />
    )
  
  }



// If no path provided redirect route

  const NoPath = ({component: Component, ...rest }) => {

    const auth = !!useSelector(state => state.auth.authenticated);
    return (
        <Route 
        {...rest}
        render={(props) => auth === true
          ? <Redirect to={{pathname: Redirections.ROUTE_LINK_HOME.partial, state: { from: props.location }}} />
          : <Redirect to={{pathname: Redirections.ROUTE_LINK_HOME_INTERNAL.partial, state: { from: props.location }}} />}
      />
    )
  
  }

  const authenticate = { NoPath, OpenRoute, PublicRoute, PrivateRoute };


  export default authenticate;