import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as actions from '../../../state/actions';
import useInput from '../../../hooks/useInput';

import * as evaluate from '../../../validators';

import { icons, materials, inputs } from '../../../components';

import { database } from '../../../services';


const SendMessage = (props) => {

    // Form inputs

    const { value:message, bind:bindMessage, reset:resetMessage } = useInput('');


    // Helper handlers

    const [generalHelper, setGeneralHelper] = useState([]);

    const [messageHelper, setMessageHelper] = useState([]);
    

    // Loading displays

    const [spinnerSwitch, setSpinnerSwitch] = useState(false);

    // actions

    const { sendMessage } = bindActionCreators(actions, useDispatch());


    const handleSubmit = (e) => {

        e.preventDefault();

        const formArray = {
            conversationID: props.conversationID,
            message
        }

        const helperArray = { 
            conversationID: setGeneralHelper,
            message: setMessageHelper
        }
        

        const resetArray = {
            conversationID: {
                shouldReset: false
            },
            message: {
                shouldReset: true,
                setterFunction: resetMessage,
                setterValue: null
            },
        }

        database.sendForm(
            sendMessage,
            formArray, 
            helperArray, 
            resetArray,
            evaluate.validSendMessage, 
            setSpinnerSwitch,
            setGeneralHelper,
            (success, response) => {                
                if (success) {
                    props.callback(response);
                }
            }
        );

    }

    return (
                    
        <Outter>
            <MessageCreator onSubmit={handleSubmit}>

                { generalHelper.length > 0 ? (
                    <ErrorFieldOuter>
                        <ErrorField>
                            { 
                                generalHelper.map((item) => {
                            
                                    
                                    return (<li>{ item }</li>)
                                
                                })
                            }
                        </ErrorField>
                    </ErrorFieldOuter>
                ) : null }

                <inputs.BasicTextInput 
                    title={`Message`}
                    placeholder={`Aa...`}
                    ready={!spinnerSwitch}
                    type={`text`}
                    typeOf={`no-outline`}
                    bind={bindMessage} 
                    value={message}
                    additionalStyling={`
                        background:#ffffff;
                        padding:15px;
                        border-radius:50px;
                    `}
                    autoFocusOnMount={true}
                    symbolRight={null}
                    errorMessage={messageHelper}
                    tabIndex={1}
                    /> 
                
                <MessageSection>
                    <EachButtonElement>
                        {spinnerSwitch ?  <materials.Spinner color='#a9a9a9' size='small'/> : <icons.SendStyled /> }
                    </EachButtonElement>
                </MessageSection>
            </MessageCreator>
        </Outter>

    )

}

export default SendMessage;

const Outter = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    width:100%;
    padding:20px;
    
`

const MessageCreator = styled.form`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    height:100px;
    width:100%;
    z-index:1001;
    flex-grow:0;
    flex-shrink:0;
    padding:10px;
    border-radius:50px;

   
    
`

const MessageSection = styled.div`
    padding-left:10px;
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    position:relative;
`



const ErrorFieldOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
`

const ErrorField = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:10px;
    font-size:14px;
    border-radius:50px;
    list-style:none;
    border: 1px dashed #F67280;
    background: #ffecee;
    margin-bottom:20px;


    & > li {
        color: red;
        margin-left:5px;
        color:red;
        text-align:center;
        width:100%;

    }
`


const EachButtonElement = styled.button`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:20px;
    border-radius:50px;
    background: #3cb598;
    border:none;
    outline:none;
    cursor:pointer;
    transition: all 1s;
    
    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }
    
    &:focus {
        transition: all 0.3s;
        transform: scale(0.95);
    }
    
    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    
    
`