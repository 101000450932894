import React, { useMemo } from 'react';
import styled from 'styled-components';

import { 
    useTable, 
    useSortBy, 
    useGlobalFilter, 
    useFilters, 
    usePagination,
    useRowSelect,
    useColumnOrder 
} from 'react-table';

import { inputs, materials } from '../../../components';

import { format } from '../../../services';



const DataTable = ({ data: inputData, fields }) => {

    const parsedFields = fields.map((col) => {

        const { name, accessor, minWidth, width, flex, display } = col;

        let value = {
            Header: () => (<R flexType={`center`}>{ name }</R>),
            accessor: accessor,
            minWidth: minWidth,
            Cell: ({ value }) => (<R flexType={`center`}>
                {display(value)}
            </R>),
        }

        if (!!width) {
            value.width = width
        }

        if (!!flex) {
            value.flex = flex
        }

        return value;
        
    })


    const columns = useMemo(() => parsedFields, 
    
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]);

    const data = useMemo(() => inputData, [inputData]);
    
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        gotoPage,
        pageCount,
        prepareRow,
        state,
        allColumns,
        getToggleHideAllColumnsProps
    } = useTable(
        { 
            columns, 
            data,
            initialState: { pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useColumnOrder,
        useRowSelect
        );


    const { pageIndex, pageSize } = state;

    return (
            <FlexContainer>

                    { /* TABLE */ }
                    
                    <Container>
                    
                        <Table {...getTableProps}>
                            <TableHead>
                                {
                                    headerGroups.map( headerGroup => (
                                        <TableRowHeader {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map( column => {
                                                    return (
                                                    <TableHeader {...column.getHeaderProps({
                                                        style: {
                                                            minWidth: column.minWidth,
                                                            width: column.width,
                                                            flex: column.flex,
                                                            fontSize: 13,
                                                        }

                                                    })}>
                                                        {column.render('Header')}
                                                        {/* <SpanElement>
                                                            {column.isSorted ? (column.isSortedDesc ? <Arrows direction='up' color='#000' size={5} /> : <Arrows direction='down' color='#000' size={4} /> ) : '  ' }
                                                        </SpanElement>
                                                        <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                                                    </TableHeader>
                                                )
                                            })
                                        }
                                        </TableRowHeader>
                                    ))
                                }
                            </TableHead>
                            <TableBody {...getTableBodyProps()}>
                                {
                                    page.map( row => {
                                        prepareRow(row);
                                        return (
                                            <TableRow {...row.getRowProps()}>
                                                {
                                                    row.cells.map( cell => {
                                                        // console.log(cell);
                                                        return <TableCell {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.column.minWidth,
                                                                flex: cell.column.flex,
                                                                width: cell.column.width,
                                                            }
                                                        })}>{cell.render('Cell')}</TableCell>
                                                    })
                                                }
                                            </TableRow>
                                        )
                                    })
                                }

                                { page.length < 5 ? (
                                    <TableRowFinal>
                                        <td>No more invoices { pageSize }</td>
                                    </TableRowFinal>
                                ) : null }
                            
                            </TableBody>
                        </Table>
                    
                
                </Container>

                { /* BOTTOM OF TABLE */ }
                                
                <VeryBottomOfTable>
                        <TopOfTableLeft>

                            <ConcatWithInput>
                                <StartText>Displaying</StartText>

                                {/* <PageNoList setWidth={'50px'}>
                                        <BaseEachNo onClick={ () => {
                                            setShowPageSize(true);
                                            document.addEventListener("mousedown", handleClickOutside);
                                        }}>
                                                <StartTextShort>{pageSize}</StartTextShort>
                                                <Arrows direction='down' color='#000000' size={4} />

                                        </BaseEachNo>

                                        
                                        <GroupOfEachNo className={showPageSize ? 'pageNos show' : 'pageNos'}>
                                            {
                                                [10, 25, 50].map(pageSize => (
                                                <EachNo key={pageSize} value={pageSize} onClick={ (e) => {
                                                    setPageSize(Number(e.target.value));
                                                    setShowPageSize(false);
                                                    document.removeEventListener("mousedown", handleClickOutside);
                                                }}>
                                                    {pageSize}
                                                </EachNo>
                                                ))
                                            }
                                        </GroupOfEachNo>
                                </PageNoList> */}
                                <StartTextShort>{Math.min(pageSize, data.length)}</StartTextShort>

                                <EndText>of {format.numberWithCommas(data.length,'','')} invoices</EndText>

                            </ConcatWithInput>

                        </TopOfTableLeft>
                        <TopOfTableRight>
                                    
                            {/* <button onClick={changeOrder}>Change Column Order</button> */}
                            { page.length < pageSize ? null : (

                                <>

                                <PageNoList setWidth={'150px'}>
                                    <GroupOfEachNo className={'pageNos show'}>
                                                <EachNo key={'all'}>
                                                    <label>
                                                        <inputs.Checkbox {...getToggleHideAllColumnsProps()} />
                                                        {' Select All'}
                                                    </label>
                                                </EachNo>
                                        {
                                            allColumns.map( column => {
                                                
                                                if (column.id === 'selection' || column.id === 'id') {
                                                    return null;
                                                }


                                                return (<EachNo key={column.id}>
                                                    <label>
                                                        <input type='checkbox' {...column.getToggleHiddenProps()} />
                                                        {' ' + column.Header}
                                                    </label>
                                                </EachNo>)
                                            })
                                        }
                                    </GroupOfEachNo>

                                </PageNoList>

                                <BorderGap />
                                
                                <ConcatWithInput>
                                    <StartText>Page</StartText>
                                    <PageInput type='number' value={pageIndex + 1} defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber);
                                        }}
                                    />
                                    <EndText>of {format.numberWithCommas(pageCount, '','')}</EndText>
                                </ConcatWithInput>


                                <ArrowGroup setWidth={120}>
                                    <ArrowButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{ <ArrowAlign>
                                            <materials.Arrows direction='left' color='#000000' size={4} />
                                            <materials.Arrows direction='left' color='#000000' size={4} />
                                        </ArrowAlign>}</ArrowButton>
                                    <ArrowButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        {<materials.Arrows direction='left' color='#000000' size={4} />}</ArrowButton>
                                    <ArrowButton onClick={() => nextPage()} disabled={!canNextPage}>
                                        {<materials.Arrows direction='right' color='#000000' size={4} />}</ArrowButton>
                                    <ArrowButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{ <ArrowAlign>
                                            <materials.Arrows direction='right' color='#000000' size={4} />
                                            <materials.Arrows direction='right' color='#000000' size={4} />
                                        </ArrowAlign>}
                                    </ArrowButton>
                                </ArrowGroup>
                                </>
                            )
                            }
                            
                        </TopOfTableRight>

            </VeryBottomOfTable>
        </FlexContainer>
    )
}
    
export default DataTable;





const FlexContainer = styled.div`
    display:flex;
    flex-wrap: nowrap;
    flex-direction:column;
    justify-content:flex-start;
    align-content:center;
    align-items:center;
    width:100%;
    box-sizing:border-box;
    border:1px solid #e5e5e5;
    border-radius:10px;
    overflow:hidden;

    
`



const VeryBottomOfTable = styled.div`
    width:100%;
    height: 50px;
    box-sizing:border-box;
    padding:20px 20px;
    border-right:1px solid transparent;
    border-top:1px solid #e9e9e9;
    border-left:1px solid transparent;
    background:#ffffff;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-content:center;
    align-items:center;
    min-width:500px;
    flex-shrink:0;
    flex-grow:0;
    box-shadow: rgba(60, 66, 87, 0.03) 0px -7px 10px 0px, rgba(0, 0, 0, 0.03) 0px -3px 10px 0px;
    z-index:10000;
`


const R = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: ${props => props.flexType};
    align-content:center;
    box-sizing:border-box;
`

const ConcatWithInput = styled.span`
    font-size:12px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding-right:10px;
    padding-left:10px;
    
`

const TopOfTableLeft = styled.div``

const TopOfTableRight = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
`

const PageNoList = styled.ul`
    text-decoration:none;
    width:${props => props.setWidth};
    position:relative;
    cursor:pointer;

`



const EachNo = styled.li`

    background:#ffffff;
    text-align:left;
    padding:5px 10px;
    position:relative;
    box-sizing:border-box;
    width:100%;
    list-style-type: none;
    cursor: pointer;
    border-bottom:1px solid #e5e5e5;
    border-left:1px solid #e5e5e5;
    border-right:1px solid #e5e5e5;

    &:hover {
        background:#eefbfb;
        color: #000000;
    }

`




const StartText = styled.div`
    padding-right:10px;
`

const StartTextShort = styled.div`
    padding:5px;
    background:#f3f3f3;
    border-radius:5px;
`


const EndText = styled.div`
    padding-left:10px;
`

const GroupOfEachNo = styled.ul`
    position:absolute;
    bottom:0;
    box-sizing:border-box;
    width:100%;
    display:none;
    text-decoration:none;
    margin: 0;
    padding: 0;
    text-align: center;
    max-height:200px;
    overflow:scroll;

`

const ArrowAlign = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
`

const ArrowButton = styled.button`
    background:#ffffff;
    outline:none;
    height:25px;
    width:25px;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content:center;
    align-items:center;
    align-content:center;
    border:1px solid #e5e5e5;
    border-radius:2px;
    cursor:pointer;
    
    &:hover {
        background:#f9f9f9;
    }

`

const ArrowGroup = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:space-between;
    align-content:space-between;
    width: ${props => Number(props.setWidth) + 'px'};


`

const PageInput = styled.input`
    padding:5px 0px;
    font-size:12px;
    background:transparent;
    border-radius:0px;
    border:1px solid #e5e5e5;
    text-align:center;
    outline:none;
    width:35px;
    
    &:hover {
        background:#f9f9f9;
    }

    &:focus {
        background:#f9f9f9;
    }

`

const BorderGap = styled.div`
    padding-left:10px;
    border-right:1px solid #e5e5e5;
    height:28px;
    width:1px;
`

const  Container = styled.div`
    display:flex;
    flex-wrap: nowrap;
    flex-direction:column;
    justify-content:flex-start;
    align-content:center;
    align-items:center;
    width:100%;
    overflow:scroll;
`

const Table = styled.table`
    border-collapse: collapse;
    position:relative;
    top:0;
    left:0;
    flex-shrink:0;
    display:block;
    flex-grow:1;
    width:100%;
    box-sizing:border-box;
    top:0;
    z-index: 1;
    overflow-x: scroll;
    overflow-y: scroll;
`

const TableCell = styled.td`
    padding: 20px 8px;
    border-left:1px solid #e9e9e9;
    height:inherit;
    display:flex;
    justify-content:center;
    align-items:center;

    &:first-child {
        border-left:none;
    }

`

const TableHeader = styled.th`
    padding: 20px 8px;
    display:flex;
    justify-content:center;
    align-items:center;
    width:inherit;
    height:inherit;
`

const TableRowHeader = styled.tr`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    width:100%;
    box-sizing:border-box;
    background:#ffffff;
    border-bottom:1px solid #e9e9e9;
    height:60px;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 10px 0px, rgba(0, 0, 0, 0.03) 0px 3px 10px 0px;
    z-index:10000;
`


const TableRow = styled.tr`

    display:flex;
    flex-direction:row;
    justify-content:flex-start
    align-content:center;
    align-items:center;
    width:100%;
    height:90px;
    box-sizing:border-box;
    background:#f9f9f9;

    &:hover {
        background-color: #f1f1f1;
    }
`


const TableRowFinal = styled.tr`

    display:flex;
    flex-direction:row;
    justify-content:center;
    align-content:center;
    align-items:center;
    width:100%;
    height:50px;
    box-sizing:border-box;
    border-top:1px solid #e5e5e5;

    & > td {
        color:#a9a9a9;
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:13px;
    }
`


const TableHead = styled.thead`

    box-sizing:border-box;
    display:flex;
    flex-direction:row;
    width:100%;

    & > ${TableRow}:first-child {
        border-top: none;
    }

`

const TableBody = styled.tbody`
    display:flex;
    flex-direction:column;
    width:100%;
    height:400px;
    overflow:scroll;
    box-sizing:border-box;
`
