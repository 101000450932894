import React from 'react';
import styled from 'styled-components';


const Message = ({ own, tip, children }) => {

    return (

        <Container own={own}>
            <FormattedMessage own={own}>
                { children }
                { own ? ( 
                    <SenderContainer>

                    </SenderContainer> 
                ) : null }
                <HelperMessage position={`top`}>{tip}</HelperMessage>
            </FormattedMessage>
        </Container>

    )

}

export default Message;


const Container = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:${props => props.own ? 'flex-end' : 'flex-start'};
    align-content:center;
    width:100%;
    padding:5px 10px;
`

const HelperMessage = styled.div`
    visibility: hidden;
    max-width: 200px;
    background-color: rgba(0,0,0,0.7);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    ${props => {
        switch (props.position) { 
            case `above`:
                return `
                    top: -35px;
                    left: 50%;
                    margin-left:-70px;
                `;
            case `below`:
                return `
                    bottom: -35px;
                    left: 50%;
                    margin-left:-70px;
                `;
            case `left`:
                return `
                    top: 50%;
                    left: -200px;
                    margin-top:-35px;
                `;
            case `right`:
                return `
                    top: 50%;
                    right: -200px;
                    margin-top:-35px;
                `;
            default:
                return `
                    top: -35px;
                    left: 50%;
                    margin-left:-70px;
                `;
        }
    }};
    opacity: 0;
    transition: opacity 0.3s;
    font-size:14px;
    
    &::after {
        content: "";
        position: absolute;
        ${props => {
            switch (props.position) { 
                case `above`:
                    return `
                        top: 100%;
                        left: 50%;
                        margin-left: -5px;
                    `;
                case `below`:
                    return `
                        bottom: 100%;
                        left: 50%;
                        margin-left: -5px;
                    `;
                case `left`:
                    return `
                        left: 100%;
                        top: 50%;
                        margin-top: -5px;
                    `;
                case `right`:
                    return `
                        right: 100%;
                        top: 50%;
                        margin-top: -5px;
                    `;
                default:
                    return `
                        top: 100%;
                        left: 50%;
                        margin-left: -5px;
                    `;
            }
        }};
        border-width: 5px;
        border-style: solid;
        border-color: ${props => {
            switch (props.position) { 
                case `above`:
                    return `
                        rgba(0,0,0,0.7)
                        transparent 
                        transparent 
                        transparent
                    `;
                case `below`:
                    return `
                        transparent 
                        transparent 
                        rgba(0,0,0,0.7)
                        transparent
                    `;
                case `left`:
                    return `
                        transparent 
                        transparent 
                        transparent
                        rgba(0,0,0,0.7) 
                    `;
                case `right`:
                    return `
                        transparent 
                        rgba(0,0,0,0.7)
                        transparent 
                        transparent
                    `;
                default:
                    return `
                        rgba(0,0,0,0.7)
                        transparent 
                        transparent 
                        transparent
                    `;
            }
        }};
    }
`

const FormattedMessage = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-content:center;
    position:relative;
    max-width:90%;
    height:100%;
    background: ${props => props.own ? 'rgba(0,146,255,1)' : '#e1e1e1' };
    background: ${props => props.own ? 'linear-gradient(135deg, rgba(0,146,255,1) 0%, rgba(0,212,255,1) 100%);' : '#e1e1e1' };
    border-radius:25px;
    padding:10px 15px;
    color: ${props => props.own ? '#ffffff' : '#000000' };
    box-shadow: rgba(229, 229, 229, 0.5) 0px 4px 5px 0px, rgba(229, 229, 229, 0.5) 0px 1px 5px 0px;

    &:hover > ${HelperMessage} {
        visibility: visible;
        opacity: 1;
    }

`

const SenderContainer = styled.div`
    display:flex;
`
