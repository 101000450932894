import { config } from '../../constants/config';

// ----------------
// CLIENT
// ----------------

// Primary redirections

export const REDIRECT_DEFAULT_SIGNEDIN = '/home';
export const REDIRECT_DEFAULT_SIGNEDOUT = '/';

// ----------------
// SERVER
// ----------------

// Authentication Routes

export const ROUTE_AUTH_SIGNUP = config.SERVER_API_URL + '/signup';
export const ROUTE_AUTH_SIGNIN = config.SERVER_API_URL + '/login';
export const ROUTE_AUTH_FORGOT = config.SERVER_API_URL + '/forgot';
export const ROUTE_AUTH_RESET = config.SERVER_API_URL + '/reset';
export const ROUTE_RSVP = config.SERVER_API_URL + '/RSVP';


// Account Routes
export const ROUTE_ACCOUNT_GET_DETAILS = config.SERVER_API_URL + '/account/get';
export const ROUTE_ACCOUNT_UPDATE = config.SERVER_API_URL + '/account/update';

// ---

export const ROUTE_ACCOUNT_EXISTS = config.SERVER_API_URL + '/account/exist';
export const ROUTE_ACCOUNT_ORDER_NEWADDRESS = config.SERVER_API_URL + '/order/address/new';
export const ROUTE_ACCOUNT_ORDER_EXISTINGADDRESS = config.SERVER_API_URL + '/order/address/existing';


// Subscription Routes
export const ROUTE_SUBSCRIPTION_GETPLAN = config.SERVER_API_URL + '/subscription/get';
export const ROUTE_SUBSCRIPTION_SUBSCRIBE = config.SERVER_API_URL + '/subscription/subscribe';
export const ROUTE_SUBSCRIPTION_UNSUBSCRIBE = config.SERVER_API_URL + '/subscription/unsubscribe';


// BILLS
export const ROUTE_BILLS_GET = config.SERVER_API_URL + '/bills/get';

// Payment Routes
export const ROUTE_PAYMENT_CREATE_CUSTOMER = config.SERVER_API_URL + '/payment/create';
export const ROUTE_PAYMENT_GET_CARDS = config.SERVER_API_URL + '/payment/cards';
export const ROUTE_PAYMENT_DELETE_CARD = config.SERVER_API_URL + '/payment/delete';
export const ROUTE_PAYMENT_DEFAULT_CARD = config.SERVER_API_URL + '/payment/default';



// Messaging Routes

export const ROUTE_MESSAGE_SEND = config.SERVER_API_URL + '/message/send';
export const ROUTE_MESSAGE_GETCONTACTS = config.SERVER_API_URL + '/message/contacts';
export const ROUTE_MESSAGE_GETCONVERSATIONS = config.SERVER_API_URL + '/message/conversations';
export const ROUTE_MESSAGE_GETMESSAGES = config.SERVER_API_URL + '/message/texts';


// Image Routes
export const ROUTE_IMG_UPLOAD = config.SERVER_API_URL + '/files/images/upload';
export const ROUTE_IMG_GET = config.SERVER_API_URL + '/files/images/get';

// Product Routes
export const ROUTE_PRODUCTS_GETALL = config.SERVER_API_URL + '/products/getall';
export const ROUTE_PRODUCTS_ACTIVATE_SUBSCRIBE = config.SERVER_API_URL + '/products/activate';
export const ROUTE_PRODUCTS_LINK_AUDIANCE = config.SERVER_API_URL + '/products/linkaudiance';
export const ROUTE_PRODUCTS_DELINK_AUDIANCE = config.SERVER_API_URL + '/products/delinkaudiance';

// Audiance Routes
export const ROUTE_AUDIANCES_GETALL = config.SERVER_API_URL + '/audiances/getall';
export const ROUTE_AUDIANCES_CREATE_NEW = config.SERVER_API_URL + '/audiances/create';
export const ROUTE_AUDIANCES_ADD_CONTACTS = config.SERVER_API_URL + '/audiances/addcontacts';
export const ROUTE_AUDIANCES_GET_CONTACTS = config.SERVER_API_URL + '/audiances/getcontacts';
export const ROUTE_AUDIANCES_REMOVE_CONTACT = config.SERVER_API_URL + '/audiances/removecontact';


// Design Routes
export const ROUTE_DESIGNS_GETALL = config.SERVER_API_URL + '/designs/getall';
export const ROUTE_DESIGNS_CREATE = config.SERVER_API_URL + '/designs/create';

// Wedding
export const ROUTE_WEDDING_GETINFO = config.SERVER_API_URL + '/wedding/info';
export const ROUTE_WEDDING_UPDATESTAY = config.SERVER_API_URL + '/wedding/stay';
export const ROUTE_WEDDING_UPDATEFLIGHT = config.SERVER_API_URL + '/wedding/flight';
