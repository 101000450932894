export const sendMessage = {

    conversationID: {
        presence: {allowEmpty: false}
    },

    message: {
        presence: {allowEmpty: false}
    }

}

