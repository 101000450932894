import { useState } from "react";

const useInput = (initialValue, type) => {

    const [value, setValue] = useState(initialValue);


    return {
        value,
        setValue,
        reset: (val) => setValue(!val ? `` : val),
        bind: {
             value,
             onChange: (e) => {
                 setValue(e.target.value)
             }
        }
    }

}

export default useInput;