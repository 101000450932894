import React from 'react';
import { Document, Page, Image, StyleSheet, Text, View } from '@react-pdf/renderer';


import * as assets from '../../../assets';

import moment from 'moment-timezone';


export const Invoice = ({invoice}) => {

    const { 
        invoice_no,
        dueDate,
        billedToName,
        billedToEmail,
        invoiceDate,
        lineItemsDue,
        lineItemsPaid,
        totalDue,
        totalOutstanding
    } = invoice;

    return (<Document>
        <Page size="A4" style={styles.page}>
            <Image style={styles.logo} src={assets.LogoFull} />
            <View style={styles.titleContainer}>
                <Text style={styles.reportTitle}>INVOICE</Text>
                <Text style={styles.reportTitle}>#{invoice_no}</Text>
            </View>
            <View style={styles.information}>
                <View style={styles.informationsection}>
                    <Text style={styles.title}>Billed to:</Text>
                    <Text style={styles.detail}>{billedToName}</Text>
                    <Text style={styles.detail}>{`(${billedToEmail})`}</Text>
                </View>
                <View style={styles.informationsectionTwo}>
                    <Text style={styles.title}>Invoice No:</Text>
                    <Text style={styles.detail}>{invoice_no}</Text>
                    <Text style={styles.title}>Invoice Date:</Text>
                    <Text style={styles.detail}>{ moment(Number(invoiceDate)).format("D MMM YYYY")}</Text>
                </View>
            </View>
            <View style={styles.headerContainer}>
                <Text style={styles.description}>Description</Text>
                <Text style={styles.middle}>Price</Text>
                <Text style={styles.middle}>Tax</Text>
                <Text style={styles.middle}>Total</Text>
            </View>
            <View style={styles.tableContainer}>
                {lineItemsDue.map( (item, index) => {

                    const { 
                        description,
                        periodStart,
                        periodEnd,
                        amountBeforeTax,
                        tax,
                        amount
                    } = item;

                    return (<View style={index + 1 !== lineItemsDue.length ? styles.row : styles.rowFinal } key={index}>
                        <View style={styles.description}>
                            <Text style={styles.content}>{description}</Text>
                            <Text style={styles.contentSecond}>{`(${moment(Number(periodStart)).format("D MMM YYYY")} to ${moment(Number(periodEnd)).format("D MMM YYYY")})`}</Text>
                        </View>
                        <Text style={styles.middle}>{amountBeforeTax}</Text>
                        <Text style={styles.middle}>{tax}</Text>
                        <Text style={styles.middle}>{amount}</Text>
                    </View>)
                   
                })}
            </View>
            <View style={styles.tableContainerTotals}>
                <View style={styles.totalRow}>
                    <Text>Total (due {moment(Number(dueDate)).format("D MMM YYYY")})</Text>
                    <Text>{ totalDue.beforeTaxToPay }</Text>
                    <Text>{ totalDue.taxToPay }</Text>
                    <Text>{ totalDue.amountToPay }</Text>
                </View>
                {lineItemsPaid.map( (item, index) => {
                    const {
                        cardBrand,
                        cardLast4Digits,
                        paymentDateTime,
                        paidAmount,
                        success
                    } = item;

                    return (
                        <View style={styles.totalRowOther} key={index}>
                            {
                                success ? (
                                    <>
                                        <Text>{`Less amount paid (paid by ${cardBrand.toUpperCase()} ***${cardLast4Digits} at ${moment(Number(paymentDateTime)).format("D MMM YYYY hh:mma")}`}</Text>
                                        <Text>{`(${paidAmount})`}</Text>
                                    </>
                                ) : (
                                    <Text>{`Payment of ${paidAmount} failed with ${cardBrand.toUpperCase()} ***${cardLast4Digits} at ${moment(Number(paymentDateTime)).format("D MMM YYYY hh:mma")}`}</Text>
                                )
                            }
                            
                        </View>
                    )
                })}
                
                <View style={styles.totalRowOther}>
                    <Text>Amount outstanding</Text>
                    <Text>{totalOutstanding.amount}</Text>
                </View>
            </View>

            <View style={styles.information}>
                <View style={styles.informationsectionThree}>
                    <Text style={styles.titleBottom}>Loanpool Pty Ltd</Text>
                    <Text style={styles.detail}>SURRY HILLS NSW AUSTRALIA</Text>
                    <Text style={styles.detail}>ABN 77 668 010 244</Text>
                </View>
            </View>

        </Page>
    </Document>
    )
};



export const invoiceData = {
    id: "5df3180a09ea16dc4b95f910",
    invoice_no: "201906",
    company: "Loanpool",
    email: "susanafuentes@mantrix.com",
    phone: "+1 (872) 588-3809",
    address: "922 Campus Road, Drytown, Wisconsin, 1986",
    trans_date: "2019-09-12",
    due_date: "2019-10-12",
    total: "$2,283.74",
    items: [
      {
        sno: 1,
        desc: "ad sunt culpa occaecat qui",
        qty: 5,
        rate: 405.89,
      },
      {
        sno: 2,
        desc: "cillum quis sunt qui aute",
        qty: 5,
        rate: 373.11,
      },
      {
        sno: 3,
        desc: "ea commodo labore culpa irure",
        qty: 5,
        rate: 458.61,
      },
      {
        sno: 4,
        desc: "nisi consequat et adipisicing dolor",
        qty: 10,
        rate: 725.24,
      },
      {
        sno: 5,
        desc: "proident cillum anim elit esse",
        qty: 4,
        rate: 141.02,
      },
    ],
  };



const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    informationsection: {
        flexDirection:'column',
        width:'100%',
    }, 
    informationsectionTwo: {
        flexDirection:'column',
        width:'50%',
        textAlign:'right'
    }, 
    informationsectionThree: {
        flexDirection:'column',
        width:'100%',
        textAlign:'right'
    }, 
    content: {
        width:'100%'
    },
    contentSecond: {
        width:'100%',
        color:'#a9a9a9'
    },
    title: {
        color:'#a9a9a9',
        fontWeight: 'bold',
        paddingTop:10
    },
    titleBottom: {
        fontWeight: 'bold',
        color:'rgb(0, 146, 255)'
    },
    detail: {
        color: '#000000'
    },
    information: {
        flexDirection: 'row',
        width:'100%',
        paddingTop: 30,
    }, 
    informationFIRST: {
        flexDirection: 'column',

    },
    informationSECOND: {
        flexDirection: 'column',
    },
    logo: {
        height: 40,
        marginLeft: 'auto'
    },
    titleContainer:{
        flexDirection: 'column',
        marginTop: 70,
        width:'100%',
    },
    reportTitle:{
        color: 'rgb(0, 146, 255)',
        letterSpacing: 4,
        fontSize: 20,
        textAlign: 'left',
        width:'50%',
        fontWeight: '500'
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderColor: '#e5e5e5',
        borderBottomLeftRadius:10,
        borderBottomRightRadius:10,
    },
    tableContainerTotals: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        borderWidth: 1,
        borderColor: '#e5e5e5',
        borderRadius:10,
        top:10,
        overflow:'hidden',
        boxSizing:'border-box',
        paddingBottom:10,
    },
    headerContainer: {
        marginTop:30,
        flexDirection: 'row',
        borderColor: 'rgb(0, 146, 255)',
        backgroundColor: 'rgb(0, 146, 255)',
        borderWidth: 1,
        color:'#ffffff',
        alignItems: 'center',
        height: 40,
        textAlign: 'center',
        fontStyle: 'bold',
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
    },
    description: {
        textAlign:'left',
        paddingLeft:15,
        width:'50%',
    },
    middle: {
        textAlign:'center',
        width:'80'
    },
    middleBordered: {
        textAlign:'center',
        borderColor:'#e5e5e5',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        alignContent:'center',
    },
    qty: {
        width: '10%',
        borderRightColor: '#90e5fc',
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: '#90e5fc',
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#e5e5e5',
        borderBottomWidth: 1,
        alignItems: 'center',
        minHeight:40,
    },
    totalRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft:15,
        paddingRight:15,
        paddingTop:15,
        paddingBottom:10,
        fontWeight:'bold',
        borderColor: '#e5e5e5',
        backgroundColor:'#f9f9f9',
        borderBottomWidth:1,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        width:'100%',
    },
    totalRowOther: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft:15,
        paddingRight:15,
        paddingTop:15,
        paddingBottom:0,
        width:'100%',
    },
    rowFinal: {
        flexDirection: 'row',
        alignItems: 'center',
        minHeight:40,
    },
    
  });




