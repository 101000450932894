import axios from 'axios';
import { JSONResponseToConsole, authHeader } from '../index';

import {
    AUTH_ERROR,
    CREATE_CARD
}  from '../types';

import { 
    CONNECTION_TO_API_NOT_WORKING 
} from '../errors';

import { 
    ROUTE_PAYMENT_CREATE_CUSTOMER,
    ROUTE_PAYMENT_GET_CARDS,
    ROUTE_PAYMENT_DELETE_CARD,
    ROUTE_PAYMENT_DEFAULT_CARD,
} from '../routes';


// -------------------------

export const getCustomer = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_PAYMENT_CREATE_CUSTOMER, formProps, { headers: authHeader() });
        JSONResponseToConsole(`POST TO /${ROUTE_PAYMENT_CREATE_CUSTOMER}`, response);
        
        // Unpack the groceries
        const { success, payload } = response.data;

        const { clientSecret } = payload;

        if (success && !!clientSecret) {

            dispatch({ type: CREATE_CARD, payload: clientSecret });
            
        }

        
        callback(success, clientSecret);
        
    } catch (e) {

        dispatch({ type: AUTH_ERROR, payload: CONNECTION_TO_API_NOT_WORKING.description });
    }
}



export const getPaymentMethods = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_PAYMENT_GET_CARDS, formProps, { headers: authHeader() });
        JSONResponseToConsole(`POST TO /${ROUTE_PAYMENT_GET_CARDS}`, response);
        
        // Unpack the groceries
        const { success, payload } = response.data;

        const { cards } = payload;

        callback(success, cards);

    } catch (e) {

        dispatch({ type: AUTH_ERROR, payload: CONNECTION_TO_API_NOT_WORKING.description });
    }


}



export const deleteCard = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_PAYMENT_DELETE_CARD, formProps, { headers: authHeader() });
        JSONResponseToConsole(`POST TO /${ROUTE_PAYMENT_DELETE_CARD}`, response);
        
        // Unpack the groceries
        const { success, payload } = response.data;

        const { cards } = payload;

        callback(success, cards);

    } catch (e) {

        dispatch({ type: AUTH_ERROR, payload: CONNECTION_TO_API_NOT_WORKING.description });
    }


}


export const defaultCard = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_PAYMENT_DEFAULT_CARD, formProps, { headers: authHeader() });
        JSONResponseToConsole(`POST TO /${ROUTE_PAYMENT_DEFAULT_CARD}`, response);
        
        // Unpack the groceries
        const { success, payload } = response.data;

        const { cards } = payload;

        callback(success, cards);

    } catch (e) {

        dispatch({ type: AUTH_ERROR, payload: CONNECTION_TO_API_NOT_WORKING.description });
    }


}

