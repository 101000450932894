import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../state/actions';

import { config } from '../../../../constants/config';

import { format } from '../../../../services';

import { icons, materials } from '../../../../components';


const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);


const PaymentMethodCard = ({ eachCard, updateCardList, spinnerSwitch, setSpinnerSwitch }) => {

    const [editPaymentMethod, setEditPaymentMethod] = useState(false);
    const [deleteOption, setDeleteOption] = useState(true);


    const resetPaymentMethodSelection = () => {
        setEditPaymentMethod(false)
        setDeleteOption(true)
    }


    const { 
        deleteCard,
        defaultCard
    } = bindActionCreators(actions, useDispatch());

    const makeDeleteCard = (id) => {
        setSpinnerSwitch(true)
        resetPaymentMethodSelection()
        deleteCard({ methodId: id }, (success, response) => {
            if (success) {
                updateCardList(response)
                setSpinnerSwitch(false)
            }
        })
    }

    const makeDefaultCard = (id) => {
        setSpinnerSwitch(true)
        resetPaymentMethodSelection()
        defaultCard({ methodId: id}, (success, response) => {
            if (success) {
                updateCardList(response)
                setSpinnerSwitch(false)
            }
        })
    }

    useEffect(() => {
        resetPaymentMethodSelection()
    }, [eachCard]);


    return (
        <Card updater={'false'} key={eachCard._id}>

            <CardInner active={!!editPaymentMethod ? 'true' : 'false'} defaultCard={eachCard.defaultMethod}>
                { !editPaymentMethod ? null : (
                
                        deleteOption ? (
                            <ButtonSmall type={'edit'} onClick={() => setDeleteOption(false)} backgroundCol={'#FF7F7F'}>
                                <icons.CancelOutlinedStyled />
                            </ButtonSmall>
                        ) : (
                            <ButtonSmall type={'edit'} onClick={() => setDeleteOption(true)} backgroundCol={'#e5e5e5'}>
                                <icons.ArrowBackStyled />
                            </ButtonSmall>
                        )
                    
                )}
                <CardIcon>
                    {!spinnerSwitch ? (
                        <>
                            <icons.CreditCardRoundedStyled active={ 'true' } /> 
                            <CardTitle>
                                {format.toTitleCase(eachCard.cardBrand)}
                            </CardTitle>
                        </>

                    ) : (<materials.Spinner color='#ffffff' size='small'/>) }
                   
                </CardIcon>
                <CardDetailPlacement>
                    { !spinnerSwitch ? (
                        <>
                            <CardDetailPlacementText>{format.toTitleCase(eachCard.cardBrand) + " ***" + eachCard.cardLast4Digits}</CardDetailPlacementText>
                            <CardDetailPlacementText>{`Exp ${eachCard.cardExpiryMonth}/${eachCard.cardExpiryYear}`}</CardDetailPlacementText>
                        </>
                    ) : (
                        <materials.Spinner color='#e5e5e5' size='small'/>
                    ) }
                    
                </CardDetailPlacement>

                { !!eachCard.defaultMethod ? (
                
                    <EditButtons>

                            {eachCard.defaultMethod ? (<CardDetailPlacementBox>Default Card</CardDetailPlacementBox>) : null }

                    </EditButtons> 
                    
                    ) : (

                    !editPaymentMethod ? (
                        <EditButtons>

                            <Button type={'edit'} onClick={() => setEditPaymentMethod(true)}>
                                <ButtonText type={'edit'}>Update</ButtonText>
                            </Button>

                        </EditButtons>   
                    ) : (
                        <EditButtons>
                            
                            {
                                !deleteOption ? ( 
                                    <ButtonLong type={'submit'} oftype={'save'} onClick={() => makeDeleteCard(eachCard._id)} backgroundCol={'#FF7F7F'}>
                                        <ButtonTextInner type={'save'}>Confirm Delete</ButtonTextInner>
                                    </ButtonLong>
                                ) : (
                                    <ButtonLong type={'submit'} oftype={'save'} onClick={() => makeDefaultCard(eachCard._id)} backgroundCol={'#3cb598'}>
                                        <ButtonTextInner type={'save'}>Make Default</ButtonTextInner>
                                    </ButtonLong>
                                )

                            }

                            <Button type={'edit'} onClick={() => resetPaymentMethodSelection()}>
                                <ButtonText type={'edit'}>Cancel</ButtonText>
                            </Button>

                        </EditButtons>
                    ) 

                )}
                
                


            </CardInner>

            

        </Card>
    )
}












const InnerForm = ({ setAddNewPaymentMethod }) => {
    const elements = useElements();
    const stripe = useStripe();

    const [errorMessage, setErrorMessage] = useState(null);

    const [pendingAccountSubmit, setPendingAccountSubmit] = useState(false);

    const { 
        getCustomer
    } = bindActionCreators(actions, useDispatch());


    const handlePaymentSubmit = async (e) => {
        e.preventDefault()

        setPendingAccountSubmit(true);

        if (!stripe || !elements) {
            return null;
        }

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            console.log(submitError)
            return;
        }

        // actions
        getCustomer({}, async (success, clientsecret) => {
            
            if (success) {
                const {error} = await stripe.confirmSetup({
                    //`Elements` instance that was used to create the Payment Element
                    elements,
                    clientSecret: clientsecret,
                    confirmParams: {
                      return_url: 'https://dev.neeocity.com/account/billing',
                    },
                });
        
                if (error) {
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Show error to your customer (for example, payment
                    // details incomplete)
                    setErrorMessage(error.message);
                  } else {
                    // Your customer will be redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer will be redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                  }
            }

        })

        

    }



    return (
        <FormToDo onSubmit={handlePaymentSubmit}>

            { !pendingAccountSubmit ? (
                <PaymentElement />
            ) : (
                <materials.Spinner color='#a9a9a9' size='small'/>
            )}

            <EditButtons>
                                                                                    
                <Button disabled={!stripe || pendingAccountSubmit} type={'submit'} oftype={'save'}>
                    <ButtonText type={'save'}>Add</ButtonText>
                </Button>

                <Button type={'edit'} onClick={() => setAddNewPaymentMethod(false)}>
                    <ButtonText type={'edit'}>Cancel</ButtonText>
                </Button>

            </EditButtons>

            {errorMessage && <div>{errorMessage}</div>}
        </FormToDo>
    )
}




const AccountBillingCards = (props) => {


    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    const [addNewPaymentMethod, setAddNewPaymentMethod] = useState(false);

    const [cards, setCards] = useState([])

    // actions

     const { 
        getPaymentMethods,
    } = bindActionCreators(actions, useDispatch());

    // Helpers 

    useEffect(() => {

        setSpinnerSwitch(true);
        getPaymentMethods({}, (success, response) => {

            if (success) {
                
                setCards(response)
                setSpinnerSwitch(false);
            }
        })
       
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
       
        <ListOfCards>
            <ListOfCardsColumns>

                { spinnerSwitch ? (
                    <materials.Spinner color='#a9a9a9' size='small'/>
                ) : (

                    <>

                    {!addNewPaymentMethod ? (
                    
                        cards.map((eachCard) => {

                            return (
                                <PaymentMethodCard eachCard={eachCard} updateCardList={setCards} spinnerSwitch={spinnerSwitch} setSpinnerSwitch={setSpinnerSwitch}/>
                            )

                        })
                    
                    ) : null}
                    

                    <Card updater={!!addNewPaymentMethod ? 'true' : 'false'}>
                        <CardInner active={!!addNewPaymentMethod ? 'true' : 'false'}>

                            
                            { addNewPaymentMethod ? (
                                <AboveEverything>
                                    <FieldTitleNew>Add New Payment Method</FieldTitleNew>
                                    <FieldTitleNewSubText>Note that this will automatically become your default payment method</FieldTitleNewSubText>

                                    <Elements stripe={stripePromise} options={{
                                        mode: 'setup',
                                        currency: 'aud'
                                    }}>
                                        <InnerForm setAddNewPaymentMethod={setAddNewPaymentMethod} />
                                    
                                    </Elements>
                                </AboveEverything>
                            ) : (
                                <>

                                    <EditButtonsOther>

                                        <ButtonOther type={'go'} onClick={() => setAddNewPaymentMethod(true)}>
                                            <ButtonText type={'go'}>Add a payment method</ButtonText>
                                        </ButtonOther>

                                    </EditButtonsOther> 

                                </>
                            )}
                        </CardInner>
                    </Card>

                    </>
                )}
                

                
            </ListOfCardsColumns>

            <Explaination>Loanpool does not store your payment credentials. They are encrypted and passed to Stripe through their API that we use to process all payments. This API allows us to create a protected link to the payment method as a secure way to purchase anything on our site in two clicks.</Explaination>

        </ListOfCards>
                        
    )

}

export default AccountBillingCards;


const FormToDo = styled.form`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    height:320px;
    padding-top:20px;
`


const CardTitle = styled.span`
    display:flex;
    flex-direction:row;
    color:#ffffff;
    font-size:16px;
    padding-left:10px;
    font-weight:bold;
`


const AboveEverything = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    min-width:600px;
`




const Explaination = styled.div`
    display:flex;
    width:100%;
    font-size:13px;
    color:#a9a9a9;
`



const ListOfCards = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    margin-top:-50px;

`

const ListOfCardsColumns = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    min-height:300px;
`

const Card = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:${props => props.updater === 'true' ? '400px' : '150px'};
    padding:10px 0px;
`

const CardInner = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    height:100%;
    width:100%;
    border-radius:10px;
    padding:10px 30px;
    background:${props => props.active === 'true' ? '#f5fafb' : 'transparent'};
    border: ${props => props.defaultCard ? '1px dashed rgb(0, 146, 255)' : '1px solid transparent'};

    &:hover {
        background:#f5fafb;
    }
`


const CardIcon = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:80px;
    width:130px;
    border-radius:10px;
    background: rgba(0,146,255,1);
    background: linear-gradient(135deg, rgba(0,146,255,1) 0%, rgba(0,212,255,1) 100%);
    color: #ffffff;
    transition: all 0.5s;

`

const CardDetailPlacement = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    padding-left:20px;
`

const CardDetailPlacementText = styled.span`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color: #000000;
    font-size:13px;
    height:25px;

`

const CardDetailPlacementBox = styled.span`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color: #ffffff;
    font-size:13px;
    height:25px;
    border-radius:5px;
    padding:10px;
    background:#a1a1a1;
    margin-right:5px;

`






const Button = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => props.type === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonLong = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => !props.backgroundCol ? '#e5e5e5' : props.backgroundCol};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonSmall = styled.button`
    color: #000000;
    box-sizing:border-box;
    font-size:15px;
    padding: 5px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => !props.backgroundCol ? '#e5e5e5' : props.backgroundCol};
    border-radius:50px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    margin-right:30px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonOther = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 100px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    white-space:nowrap;
    background: ${props => props.type === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`





const ButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    width:50px;
    align-items:center;
    justify-content:center;
    text-align:center;

`


const ButtonTextInner = styled.span`
    display:flex;
    color: #ffffff;
    font-size:15px;
    width:120px;
    align-items:center;
    justify-content:center;
    text-align:center;

`




const FieldTitleNew = styled.span`
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    font-weight:bold;
    width:100%;
`

const FieldTitleNewSubText = styled.span`
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    width:100%;
    color:#a9a9a9;
    font-size:13px;
    padding-top:5px;
`



const EditButtons = styled.span`
    flex-grow:1;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    padding-left:20px;
`

const EditButtonsOther = styled.span`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    align-content: space-between;
    text-align:center;
    width:100%;
`
   