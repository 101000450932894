import {
    UPDATE_STICKY,
    UPDATE_SHADOW,
    UPDATE_MENU,
    UPDATE_PAGEREF
}  from '../types';


export const updateSticky = (value) => async dispatch => {
    dispatch({ type: UPDATE_STICKY, value });
}

export const updateShadow = (value) => async dispatch => {
    dispatch({ type: UPDATE_SHADOW, value });
}

export const updateMenu = (value) => async dispatch => {
    dispatch({ type: UPDATE_MENU, value });
}

export const updatePageRef = (value) => async dispatch => {
    dispatch({ type: UPDATE_PAGEREF, value });
}