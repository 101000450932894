
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../state/actions';

import { Link } from 'react-router-dom';

import { database } from '../../services';

import defaults from '../../constants/defaults';

import Redirections from '../../constants/routes';
import * as evaluate from '../../validators';

import { elements, inputs, materials } from '../../components';

import useInput from '../../hooks/useInput';


const ForgotPage = (props) => {

    // General
    const [pageReady, setpageReady] = useState(true);
    const [spinnerSwitch, setSpinnerSwitch] = useState(false);

    // Inputs
    const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');

 
     // Helper handlers
     const [generalHelper, setGeneralHelper] = useState([]);

     const [emailHelper, setEmailHelper] = useState([]);


    useEffect(() => {
        setGeneralHelper([])
        setEmailHelper([])
     }, [email])


 
     const { forgot } = bindActionCreators(actions, useDispatch());
 
     const handleSubmit = (e) => {
 
         e.preventDefault()

         console.log('here')
 
         const formArray = { email }
 
         const helperArray = { 
             email: setEmailHelper, 
         }
 
         const resetArray = {
             email: {
                 shouldReset: true,
                 setterFunction: resetEmail,
                 setterValue: null
             },
         }
 
         database.sendForm(
             forgot,
             formArray, 
             helperArray, 
             resetArray,
             evaluate.validForgot,
             setSpinnerSwitch,
             setGeneralHelper,
             (success) => {
                 if (success) {
                    props.history.push(Redirections.ROUTE_LINK_RESET.partial)
                 }
             }
         );
     
    }

    // Clear errors

    useEffect(() => {
        setpageReady(true)
    }, [])
    
    return (
        <elements.PageContainer 
            pageReady={pageReady}
            pageTitle={defaults.tabbar.GENERAL}
            sticky={true}
            cover={true}
            menu={'none'}
        >
            <ContainerContent onSubmit={handleSubmit}>
            
                <ContentElement>

                    <TopSection>
                        <Title to={`login`} location={'left'} active={'false'}>Log in</Title>
                        <Title to={`join`} location={'right'} active={'false'}>Join</Title>
                    </TopSection>

                    <BottomSection>

                        { generalHelper.length > 0 ? (
                            <ErrorFieldOuter>
                                <ErrorField>
                                    { 
                                        generalHelper.map((item) => {
                                        
                                            
                                            return (<li>{ item }</li>)
                                        
                                        })
                                    }
                                </ErrorField>
                            </ErrorFieldOuter>
                        ) : null }

                        <FormField>
                            <FormTitles>
                                <FieldTitleHeading>Reset Password</FieldTitleHeading>
                            </FormTitles>
                        </FormField>


                        <FormField>
                            <FormTitles>
                                <FieldTitle>Enter your email address</FieldTitle>
                            </FormTitles>
                            <inputs.BasicTextInput 
                                title={`Email`}
                                placeholder={``}
                                ready={!spinnerSwitch}
                                type={`text`}
                                bind={bindEmail} 
                                value={email}
                                additionalStyling={`
                                    background:#f9f9f9;
                                `}
                                autoFocusOnMount={true}
                                symbolRight={null}
                                errorMessage={emailHelper}
                                tabIndex={1}
                                /> 
                        </FormField>
                        
                        <Button tabIndex={2} disabled={spinnerSwitch}>
                            { !spinnerSwitch ? 'Continue' : <materials.Spinner color='#ffffff' size='small'/> }
                        </Button>

                    </BottomSection>
 
                </ContentElement>

            
            </ContainerContent>

        </elements.PageContainer>
    )

}

export default ForgotPage;


const FormField = styled.div`
    padding:10px 0px;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`


const ContainerContent = styled.form`
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`

const TopSection = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    flex-wrap: nowrap;
    box-sizing: border-box;
    width:100%;
    position:relative;
    border:none;
    box-sizing:border-box;
    background:#0092ff;
    
`



const BottomSection = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    flex-wrap: nowrap;
    box-sizing: border-box;
    padding:25px;
    border-radius:20px;
    background:#ffffff;
`



const ErrorFieldOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
`

const ErrorField = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:10px;
    font-size:14px;
    border-radius:50px;
    list-style:none;
    border: 1px dashed #F67280;
    background: #ffecee;
    margin-bottom:20px;


    & > li {
        color: red;
        margin-left:5px;
        color:red;
        text-align:center;
        width:100%;

    }
`

const ContentElement = styled.div`
    box-sizing: border-box;
    border:none;
    background: #ffffff;
    border-radius:20px;
    width: 500px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
`



const FormTitles = styled.span`
    font-size:14px;
    font-weight:500;
    padding-bottom:10px;
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing:border-box;

`
const FieldTitleHeading = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    font-size:20px;
    font-weight:bold;
`

const FieldTitle = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
`


// #4CD964;
const Button = styled.button`
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 25px 0px;
    outline:0;
    margin-top:20px;
    cursor:pointer;
    transition: all 0.5s;
    width:100%;
    background: #3cb598;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    
    
    font-weight:bold;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:focus {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

const Title = styled(Link)`
    font-size:20px;
    font-weight:200;
    color: ${props => props.active === 'true' ? '#0066CC' : '#ffffff' };
    background: ${props => props.active === 'true' ? '#ffffff' : '#0066CC'};
    padding:25px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-decoration:none;
    flex:1;
    opacity:0.6;
    transition:all 0.5s;
    ${props => props.location === 'left' ? (
        `
         border-top-left-radius:20px;
         border-bottom:5px solid #0092ff;
        border-right:5px solid #0092ff;
        border-top-right-radius:20px;
        `
    ): 
    null }
    ${props => props.location === 'right' ? (
        `

        border-bottom:5px solid #0092ff;
        border-left:5px solid #0092ff;
        border-top-left-radius:20px;
        border-top-right-radius:20px;
        
        `
    ): null }
    box-sizing:border-box;

    &:hover {
        opacity:1;
    }
`


