import React from 'react';
import styled from 'styled-components';


import Redirections from '../../../constants/routes';


const AccountPrivacy = () => {



    return (
      
            <>
                <BenefitTitle>Account Settings &gt; Privacy and security</BenefitTitle>
                    <ContentsSeperator>

                    <WarningBoundery>
                        <FormField>
                            <TransitionToColumn>

                                <FormField>
                                    <FieldTitleClose>Close account</FieldTitleClose>
                                    <FieldValueClose>Account closures are actioned manually by the team to ensure your privacy.</FieldValueClose>
                                </FormField>

                                <EditButtons>

                                    <ButtonClosure to={Redirections.ROUTE_LINK_RESET.partial} type={'save'}>
                                        <ButtonTextResetClosure type={'save'}>Request account closure</ButtonTextResetClosure>
                                    </ButtonClosure>


                                </EditButtons>

                            </TransitionToColumn>

                        
                            
                        </FormField> 
                    </WarningBoundery>


                </ContentsSeperator>



            </>
                              
    )

}

export default AccountPrivacy;





const WarningBoundery = styled.div`
    display:flex;
    flex-direction:row;
    padding:20px;
    border:1px dashed rgb(222, 82, 51);
    border-radius:25px;
    background: #fff7f6;
    transition: all 1s;
    opacity: 0.6;

    &:hover {
        opacity: 1.0;
    }
`

const TransitionToColumn = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;


`


const ContentsSeperator = styled.div`
    padding:30px;
    padding-top:20px;
    width:100%;
    height:100%;
`



const ButtonClosure = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: #de5233;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 0.4;
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        opacity: 0.9;
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
        opacity: 1.0;
    }


`


const ButtonTextResetClosure = styled.span`
    display:flex;
    color: #ffffff;
    font-size:15px;
    align-items:center;
    justify-content:center;
    text-align:center;
    width:200px;

`




const FormField = styled.div`
    padding:10px 0px;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`



const FieldTitleClose = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    font-weight:bold;
    color:red;
`

const FieldValueClose = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    width:100%;
    color:red;
    font-size:12px;
`

const EditButtons = styled.span`
    flex-grow:1;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    padding-left:20px;
`


const BenefitTitle = styled.div`
    font-family: newFontFace, Arial, sans-serif;
    box-sizing:border-box;
    font-size:20px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    text-align:left;
    padding:30px;
    padding-top:10px;
    border-bottom: 1px solid #e5e5e5;
    width:100%;
`