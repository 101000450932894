import { createBrowserHistory } from 'history';

const hist = createBrowserHistory();

const search = hist.location.search;


function getParams (str) {
    var queryString = str || window.location.search || '';
    var keyValPairs = [];
    var params      = {};
    queryString     = queryString.replace(/.*?\?/,"");
 
    if (queryString.length)
    {
       keyValPairs = queryString.split('&');
       for (const pairNum in keyValPairs)
       {
           var [ key, value ] = keyValPairs[pairNum].split('=');

          if (!key.length) continue;
          if (typeof params[key] === 'undefined')
        //   params[key] = [];
        //   params[key].push(keyValPairs[pairNum].split('=')[1]);
            params[key] = value;

       }
    }
    return params;
 }

export const history = hist;

export const params = getParams(search);

const History = { history, params };

export default History;