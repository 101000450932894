import React from 'react';
import styled from 'styled-components';

// -----------
// CREATE STYLED COMPONENTS

const StepLabels = ({ message, step, focus, onClick }) => {

        return (
            <Container>
                
                    <Contents>
                       
                        <ContentsText onClick={onClick} focus={focus}>
                            <ContentStep>{ step }</ContentStep>
                            <ContentMessage>{ message }</ContentMessage>
                        </ContentsText>
                    </Contents>
                
            </Container>
        );
}

export default StepLabels;


const Container = styled.div`
    width:220px;
    height:100px;
    cursor:pointer;
    margin-bottom:2px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-content:center;
    align-items:center;
`


const Contents = styled.div`
    position:relative;
    width:200px;
    height:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-content:center;
    align-items:center;
    outline:none;
`

const ContentsText = styled.button`
    

    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 25px 0px;
    outline:0;
    margin-top:${props => props.focus ? '-20px' : '0px'};
    cursor:pointer;
    transition: all 0.5s;
    width:100%;
    background:${props => props.focus ? '#516670' : '#B8C1C5'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height: 100px;
    
    font-weight:bold;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }

`


const ContentStep = styled.div`
    width:20px;
    height:20px;
    display:flex;
    padding:15px;
    justify-content:center;
    align-items:center;
    align-content:center;
    background:#ffffff;
    color:#516670;
    font-size:20px;
    border-radius:25px;
`



const ContentMessage = styled.div`
    display:flex;  
    width:95%;
    padding:10px;
    color:#ffffff;
    font-size:14px;
    justify-content:center;
    align-items:center;
    align-content:center;
    text-align:center;
    border-radius:4px;
    
`
