// ----------------
// LOCAL - No domain
// ----------------
import { config } from './config';

// ---------------------
// External

const basePath = config.CLIENT_URL;

const Redirections = {
    ROUTE_LINK_HOME: {
        partial: `/`,
        full: `${basePath}/`
    },
    ROUTE_LINK_LOGIN: {
        partial: `/login`,
        full: `${basePath}/login`
    },
    ROUTE_LINK_JOIN: {
        partial: `/join`,
        full: `${basePath}/join`
    },
    ROUTE_LINK_FORGOT: {
        partial: `/forgot`,
        full: `${basePath}/forgot`
    },
    ROUTE_LINK_RESET: {
        partial: `/reset`,
        full: `${basePath}/reset`
    },
    ROUTE_LINK_MESSAGES: {
        partial: `/messages`,
        full: `${basePath}/messages`
    },
    ROUTE_LINK_ACCOUNT: {
        partial: `/account`,
        full: `${basePath}/account`
    },
    ROUTE_LINK_ACCOUNT_GENERAL: {
        partial: `/account/general`,
        full: `${basePath}/account/general`
    },
    ROUTE_LINK_ACCOUNT_BILLING: {
        partial: `/account/billing`,
        full: `${basePath}/account/billing`
    },
    ROUTE_LINK_ACCOUNT_PRIVACY: {
        partial: `/account/privacy`,
        full: `${basePath}/account/privacy`
    },
    ROUTE_LINK_SIGNOUT: {
        partial: `/signout`,
        full: `${basePath}/signout`
    },

    // Internal Application
    ROUTE_LINK_HOME_INTERNAL: {
        partial: `/cycles`,
        full: `${basePath}/cycles`
    },
    ROUTE_LINK_LISTS: {
        partial: `/lists`,
        full: `${basePath}/lists`
    },
    ROUTE_LINK_CONTENT: {
        partial: `/content`,
        full: `${basePath}/content`
    }

}

export default Redirections;