import React, { useState } from 'react';
import styled from 'styled-components';



const Images = ({ imageValue, setImageValue, setLoadingValue, size, ref }) => {

    const [targetHeight, setTargetHeight] = useState(false);

    const onFileChange = (e) => {

        let file = e.target.files;

        setLoadingValue(file[0]);
        
        // file.name, file.size, file.type

        if (file && file[0]) {

            let reader = new FileReader();
            reader.onload = function(event) {
                
                let img = new Image();
                img.src = event.target.result;

                img.onload = function() {
                    let height = this.height;
                    let width = this.width;

                    if (height >= width) {
                        setTargetHeight(false);
                    } else {
                        setTargetHeight(true);
                    }

                    setImageValue(event.target.result);

                }
 
            }

            reader.readAsDataURL(file[0]);

        } 
    };


    return (


        <Container overlay={!imageValue} size={size}>
        
            <ImageInput type="file" onChange={(e) => onFileChange(e)} ref={ref}/>

            <BackgroundOverlay overlay={!!imageValue} />
            
            {
                !imageValue ? (
                    <LoadPhoto>
                        <LoadPhotoTitle>D.</LoadPhotoTitle>
                    </LoadPhoto>
                ) : (
                    <ImageElement alt={'Display Image'} src={imageValue} targetHeight={targetHeight} />
                )
            }
        </Container>

    )

}

export default Images;


const ImageInput = styled.input`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    opacity:0;
    border:1px solid #000;
    cursor:pointer;
    z-index:30;
`



const BackgroundOverlay = styled.div`
    display:none;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    background:#ffffff;
    position:absolute;
    bottom:0;
    opacity:0.0;
    z-index:20;
    transition: all 2s;

`

const ImageElement = styled.img`
    display:flex;
    justify-content:center;
    align-items:center;
    position:absolute;
    z-index:10;

    ${props => props.targetHeight ? (
        `height:100%;`
    ) : (
        `width:100%;`
    )}
`

const LoadPhoto = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    position:absolute;
    z-index:10;
`


const Container = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    ${
        props => props.size === `large` ? (
            `width:310px;
            height:310px;`
        ) : (
            `width:120px;
            height:120px;`
        )
    }

    border:1px solid #d1d1d1;
    border-radius:100px;
    cursor:pointer;
    overflow:hidden;
    position:relative;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    transition: all 0.3s;
    background: rgba(0,146,255,1.0);
    background: linear-gradient(135deg, rgba(0,146,255,1) 0%, rgba(0,212,255,1) 100%);
    opacity:0.8;
    transition: all 0.3s;
    transform: scale(1.0);

    &:hover {
        opacity:1.0;
        border:1px solid #000000;
        box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px;
    }

    &:active {
        transform: scale(0.95);
    }

    ${
        props => props.overlay ? (
            ` &:hover > ${BackgroundOverlay} {
                display:flex;
                opacity:0.6;
            }`
        ) : null
    }
   
`


const LoadPhotoTitle = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:50px;
    color:#ffffff;
    opacity:0.8;
    font-family: newFontFace, Arial, sans-serif;
`
