import axios from 'axios';
import { JSONResponseToConsole, authHeader } from '../index';

import {
    AUTH_ERROR
}  from '../types';

import { 
    CONNECTION_TO_API_NOT_WORKING,
    INCORRECT_CREDENTIALS
} from '../errors';

import { 
    ROUTE_DESIGNS_GETALL,
    ROUTE_DESIGNS_CREATE
} from '../routes';


const handleServerErrors = (dispatch, callback, e) => {


    let message = '';

    if (e.response && e.response.status === 401) {
        message = INCORRECT_CREDENTIALS.description;
    } else {
        message = CONNECTION_TO_API_NOT_WORKING.description
    }

    dispatch({ type: AUTH_ERROR, payload: message });
    callback(false, message, true);

}


export const getAllDesigns = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_DESIGNS_GETALL, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_DESIGNS_GETALL} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }

}


export const createDesign = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_DESIGNS_CREATE, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_DESIGNS_CREATE} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }

}
