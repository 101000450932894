export const createAudiance = {

    name: {
        presence: true,
    },

}

export const checkEmail = {

    email: {
        presence: true,
        email: true
    },

}


export const addContactsToAudiance = {
    audianceID: {
        presence: true
    },
    contactsToAdd: {
        presence: true
    }
}


export const getContactsFromAudiance = {
    audianceID: {
        presence: true
    }
}



export const removeContactFromAudiance = {
    email: {
        presence: true
    },
    audianceID: {
        presence: true
    },
}
