import React from 'react';
import styled, { keyframes } from 'styled-components';


const Conversation = ({ conversationID, activateConvoID, conversationInFocus, activeMember, displayPicture, title, snippet,  }) => {


    return (

        <Container onClick={() => activateConvoID(conversationID)} active={conversationInFocus}>
            <ConversationInner active={conversationInFocus}>
                <DisplayPictureContainer>
                    <DisplayPicture src={displayPicture} />
                    <ActiveNowIndicator show={activeMember} />
                </DisplayPictureContainer>
                <DisplaySnippet>
                    <ConversationTitle>{title}</ConversationTitle>
                    <Snippet unread={snippet.unread}>
                        {`${snippet.author}: ${snippet.message} • ${snippet.lastDate}`}
                    </Snippet>
                </DisplaySnippet>
                <DisplayIndicatorContainer>
                    <DisplayIndicator unread={snippet.unread}/>
                </DisplayIndicatorContainer>
            </ConversationInner>
        </Container>

    )

}

export default Conversation;


const bounce = keyframes`
    0% {transform: scale(1.0); opacity:1.0; box-shadow: rgba(60, 66, 87, 0.12) 0px 0px 5px 0px, rgba(0, 0, 0, 0.12) 0px 0px 5px 0px;}
    50% {transform: scale(0.9); opacity:0.9; box-shadow: rgba(60, 66, 87, 0.12) 0px 0px 10px 0px, rgba(0, 0, 0, 0.12) 0px 0px 10px 0px;}
    100% {transform: scale(1.0); opacity:1.0; box-shadow: rgba(60, 66, 87, 0.12) 0px 0px 5px 0px, rgba(0, 0, 0, 0.12) 0px 0px 5px 0px;}
`

const ConversationInner = styled.div`
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:100%;
    border-radius:10px;
    transition: all 0.3s;
    background: ${props => props.active ? '#ebf4ff' : 'transparent'};
`

const Container = styled.div`
    height:100px;
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    cursor:pointer;
    overflow:hidden;
    padding:10px;


    ${props => props.active ? null : (
        `&:hover > ${ConversationInner} {
            background: #f5f5f5;
        };
        &:active > ${ConversationInner} {
            background: #e1e1e1;
        };`
    )}
    
`


const DisplayPictureContainer = styled.div`
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    justify-content:center;
    align-items:center;
    width:80px;
    height:100%;
    position:relative;
`

const DisplayPicture = styled.img`
    display:flex;
    justify-content:center;
    align-items:center;
    height:55px;
    width:55px;
    border-radius:100px;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
`

const DisplaySnippet = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    width:100%;
    height:100%;
`

const ConversationTitle = styled.span`
    font-weight:600;
    display:flex;
    font-size:14px;
`

const Snippet = styled.span`
    font-weight:${props => props.unread ? '600' : '200'};
    display:flex;
    font-size:12px;
    color: ${props => props.unread ? 'blue' : '#000000'};
`

const DisplayIndicatorContainer = styled.div`
    display:flex;
    width:30px;
    height:100%;
    flex-grow:0;
    flex-shrink:0;
    justify-content:center;
    align-items:center;
`

const DisplayIndicator = styled.div`
    width: 12px;
    height:12px;
    display:${props => props.unread ? 'flex' : 'none'};
    background: blue;
    border-radius:100px;
    flex-grow:0;
    flex-shrink:0;
    box-shadow: rgba(60, 66, 87, 0.08) 0px 4px 5px 0px, rgba(0, 0, 0, 0.08) 0px 1px 5px 0px;
    -webkit-animation-name: ${bounce};
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count:infinite;
    animation-name: ${bounce};
    animation-duration: 5s;
    animation-iteration-count: infinite;
    transform: scale(1);
    transition: all 5s;
`


const ActiveNowIndicator = styled.div`
    width: 14px;
    height:14px;
    display:${props => props.show ? 'flex' : 'none'};
    background: #31a24c;
    border-radius:100px;
    flex-grow:0;
    flex-shrink:0;
    position:absolute;
    bottom:15px;
    right:10px;
    border:2px solid #ffffff;
    
    
`
