import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import defaults from '../../constants/defaults';

import Redirections from '../../constants/routes';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../state/actions';

import { database, format } from '../../services';
import * as evaluate from '../../validators';

import * as assets from '../../assets';

import useInput from '../../hooks/useInput';

import { elements, materials, icons, inputs } from '../../components';

const AudianceLists = (props) => {
    


    const [pageReady, setpageReady] = useState(true);   

    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    const [showAddNewForm, setShowAddNewForm] = useState(false);
    const [addEmails, setAddEmails] = useState(false); 
    const [addEmailsFormload, setAddEmailsFormload] = useState(false);
    const [audianceMenu, setAudianceMenu] = useState('list');
    const [numberOfEmailsToLoad, setNumberOfEmailsToLoad] = useState(0);

    const [selectedAudiance, setSelectedAudiance] = useState('');
    const [audianceDetail, setAudianceDetail] = useState({
        name: '',
        memberCount: 0
    });

    const emailListBox = useRef();

    // Inputs
    const { value:search, bind:bindSearch, reset:resetSearch } = useInput('');
    const { value:name, bind:bindName, reset:resetName } = useInput('');


    const [inputFields, setInputFields] = useState([
        { email: '', valid: 'TBC' }
    ])

    const handleOnChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        data[index]['valid'] = 'TBC';

        setInputFields(data);

        if (inputFields[inputFields.length - 1][event.target.name] !== '') {
            addRow()
        } 

        if (inputFields[inputFields.length - 1][event.target.name] === '' && inputFields[inputFields.length - 2][event.target.name] === '') {
            removeRow(inputFields.length - 1)
        }

        const countOfEmailsNonBlank = inputFields.filter(input => input.email !== '').length;

        setNumberOfEmailsToLoad(countOfEmailsNonBlank);

        emailListBox.current.scrollTo(0, emailListBox.current.scrollHeight);
    }


    const addRow = () => {
        let newField = { email: '', valid: 'TBC' };
        setInputFields([...inputFields, newField])
    }

    const removeRow = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);

        const countOfEmailsNonBlank = data.filter(input => input.email !== '').length;

        setNumberOfEmailsToLoad(countOfEmailsNonBlank);
    }


    const checkValidEmail = (index, event) => {
        
        let data = [...inputFields];

        if (data[index][event.target.name] === '') {
            return
        }

        const preSubmissionErrors = evaluate.evaluate(data[index], evaluate.validCheckEmail);

        if (preSubmissionErrors !== undefined) {

            data[index]['valid'] = (preSubmissionErrors[event.target.name].length === 0);
            setInputFields(data);

        } else {
            data[index]['valid'] = true;
            setInputFields(data);
        }

    }


    // Helper handlers

    const [generalHelper, setGeneralHelper] = useState([]);

    const [searchHelper, setSearchHelper] = useState([]);
    const [nameHelper, setNameHelper] = useState([]);

    useEffect(() => {
        setGeneralHelper([])
        setSearchHelper([])
     }, [search])

     useEffect(() => {
        setGeneralHelper([])
        setNameHelper([])
     }, [name])

    const [audianceList, setAudianceList] = useState([]);
    const [contactList, setContactList] = useState([]);


    useEffect(() => {

        // When page is ready set to:
        // setPageReady(true);

    }, [pageReady])


    useEffect( () => {
        setpageReady(true);
    }, []) 



    const { 
        createAudiance,
        getAudiances,
        addContactsToAudiance,
        getContactsFromAudiance,
        removeContactFromAudiance
    } = bindActionCreators(actions, useDispatch());


    useEffect(() => {
        getAudiances({}, (success, payload) => {
            if (success) {
                setAudianceList(payload.audianceList);
                if (payload.audianceList.length > 0) {
                    setSelectedAudiance(payload.audianceList[0]._id);
                }
            }
        })
        setSpinnerSwitch(false)
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (selectedAudiance === '') {
            return
        }

        const audianceDoc = audianceList.find((val) => val._id === selectedAudiance);

        setAudianceDetail({
            name: audianceDoc.name,
            memberCount: audianceDoc.memberCount
        })

        const audienceDetails = {
            audianceID: selectedAudiance
        }

        getContactsFromAudiance(audienceDetails, (success, payload) => {
            if (success) {
                setContactList(payload.contactsList);
            }
        })

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAudiance]);


    const uploadEmails = (e) => {

        e.preventDefault()

        const data = inputFields
            .filter((val) => (val.email !== '' && val.valid) )
            .map((val) => ({email: val.email}));

        const formArray = { 
            audianceID: selectedAudiance,
            contactsToAdd:  data,
        };

        const helperArray = { 
            audianceID: setGeneralHelper, 
            contactsToAdd: setGeneralHelper, 
        }

        const resetArray = {
            audianceID: {
                shouldReset: false,
            },
            contactsToAdd: {
                shouldReset: true,
                setterFunction: setInputFields,
                setterValue: [{ email: '', valid: 'TBC' }]
            },
        }

     
        database.sendForm(
            addContactsToAudiance,
            formArray, 
            helperArray, 
            resetArray,
            evaluate.validAddContactsToAudiance,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
            
                if (success) {

                    const { audianceList, contactsList, audianceID } = payload;

                    setAudianceList(audianceList)
                    setContactList(contactsList);
                    setNumberOfEmailsToLoad(0);
                    setSelectedAudiance(audianceID);
                    setAddEmails(false);
                    
                    

                }
            }
        );

    }


   const removeContact = ({ email, audianceID }) => {

        const formArray = { 
            email: email,
            audianceID: audianceID,
        };

        const helperArray = { 
            email: setGeneralHelper,
            audianceID: setGeneralHelper,
        }

        const resetArray = {
            email: {
                shouldReset: false,
            },
            audianceID: {
                shouldReset: false,
            },
        }

     
        database.sendForm(
            removeContactFromAudiance,
            formArray, 
            helperArray, 
            resetArray,
            evaluate.validRemoveContactFromAudiance,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {

                    const { audianceList, audianceID, contactsList } = payload;

                    setAudianceList(audianceList)
                    setContactList(contactsList);
                    setSelectedAudiance(audianceID);
                }
            }
        );
   }

    const sendCreateAudiance = (e) => {
        
         e.preventDefault()
 
         const formArray = { name };
 
         const helperArray = { 
             name: setNameHelper, 
         }
 
         const resetArray = {
             name: {
                 shouldReset: true,
                 setterFunction: resetName,
                 setterValue: null
             },
         }
 
      
         database.sendForm(
             createAudiance,
             formArray, 
             helperArray, 
             resetArray,
             evaluate.validCreateAudiance,
             setSpinnerSwitch,
             setGeneralHelper,
             (success, payload) => {
                 if (success) {
                    const { audianceList, audianceID } = payload;
                    
                    setAudianceList(audianceList);
                    setSelectedAudiance(audianceID);
                    setShowAddNewForm(false);
                 }
             }
         );
     
    }
   

    return (
        <elements.PageContainer 
            pageReady={true}
            pageTitle={defaults.tabbar.GENERAL}
            pageRef={Redirections.ROUTE_LINK_LISTS.partial}
            sticky={false}
            cover={false}
            menu={'access'}
        >
    
                <SectionNext>
          
                        { audianceList.length !== 0 && (

                            <LeftColumnOriginal>
                                    <ItemsToShowOriginal>
                                        <ButtonGoTo active={true} onClick={() => setShowAddNewForm(false)}><icons.HomeStyled /></ButtonGoTo>
                                        <SectionName>{'Audiance Lists'}</SectionName>
                                        <ButtonGoTo active={false} onClick={() => setShowAddNewForm(true)}><icons.AddStyledWhite /></ButtonGoTo>
                                    </ItemsToShowOriginal>


                                    <LeftColumnInnerOriginal>
                                        {
                                            audianceList.map((audiance, index) => {

                                                return (
                                                    <EachMember key={index}>
                                                        <EachMemberInnerOther active={audiance._id === selectedAudiance} onClick={() => setSelectedAudiance(audiance._id)}>
                                                            <EachMemberLeft>
                                                                <NameOfMember>{audiance.name}</NameOfMember>
                                                                <RoleType>{`${audiance.memberCount} members`}</RoleType>
                                                            </EachMemberLeft>
                                                            <GoToSign>
                                                                <icons.ChevronRightStyled />
                                                            </GoToSign>
                                                        </EachMemberInnerOther>
                                                    </EachMember>
                                                    
                                                )
                                            })
                                        }
                                        
                                    </LeftColumnInnerOriginal>
                            </LeftColumnOriginal>
                        
                        )}


                        { !showAddNewForm && (

                            <LeftColumn>
                             
                                {!addEmails && (
                                    <>
                                        <TopColumnHeading>
                                            {
                                                contactList.length === 0 && (
                                                    <EachMemberNoneOther>
                                                        <RoleTypeTop>{ audianceDetail.name}</RoleTypeTop>
                                                        <RoleTypeTopLittle>{ audianceList.length === 0 ? 'Create your first audiance' : 'This audiance has no members'}</RoleTypeTopLittle>
                                                        <ButtonGoToAnother active={true} onClick={() => { audianceList.length === 0 ? setShowAddNewForm(true) : setAddEmails(true) }}><icons.AddStyledWhite />{audianceList.length === 0 ? 'Add Audiance' : 'Add Emails'}</ButtonGoToAnother>
                                                    </EachMemberNoneOther>
                                                )
                                            }

                                            {  
                                                contactList.length > 0 && (
                                                    <EachMemberNoneHorizontal>
                                                        <LeftGroupRoleType>
                                                            <RoleTypeTop>{ audianceDetail.name}</RoleTypeTop>
                                                            <RoleTypeTopLittle>{audianceDetail.memberCount + ' members'}</RoleTypeTopLittle>
                                                        </LeftGroupRoleType>
                                                        <ButtonGoToAnotherFirst active={true} onClick={() => setAddEmails(true)}><icons.AddStyledWhite /> Add Emails</ButtonGoToAnotherFirst>
                                                    </EachMemberNoneHorizontal>
                                                )
                                            }
                                        </TopColumnHeading>

                                        <LeftColumnInner>

                                        {
                                            
                                            contactList.map((contact, index) => {
                                                return (
                                                    <EachMember key={index}>
                                                        <EachMemberInner active={false}>
                                                            <HeadingTopSection>
                                                                
                                                                <EachMemberLeft>
                                                                    <NameOfMember>{contact.email}</NameOfMember>
                                                                </EachMemberLeft>
                                                                <inputs.CancelButton
                                                                    action={() => removeContact({ 
                                                                        email: contact.email,
                                                                        audianceID: selectedAudiance
                                                                    })}
                                                                    color={`#FF7F7F`}
                                                                />
                                                            </HeadingTopSection>
                                                        </EachMemberInner>
                                                    </EachMember>
                                                )
                                            })
                                        }
                                        </LeftColumnInner>
                                    </>
                                )}

                                { addEmails && (
                                    <LeftColumnInner>
                                        <TopRow>
                                            <LeftGroupRoleType>
                                                <RoleTypeTop>{ audianceDetail.name}</RoleTypeTop>
                                                <RoleTypeTopLittle>{`${numberOfEmailsToLoad} emails to load`}</RoleTypeTopLittle>
                                            </LeftGroupRoleType>
                                            <CloseButton onClick={() => setAddEmails(false)}>
                                                <icons.CancelOutlinedStyled />
                                            </CloseButton>
                                        </TopRow>

                                        {
                                            !addEmailsFormload && (
                                                <MiddleSection>
                                                    <LoadBoxOption>
                                                        <LoadBoxOptionInner onClick={() => setAddEmailsFormload(true)}>
                                                            <icons.UploadWhite />
                                                            <DescriptionText>Upload CSV</DescriptionText>
                                                        </LoadBoxOptionInner>
                                                    </LoadBoxOption>
                                                    <LoadBoxOption>
                                                        <LoadBoxOptionInner onClick={() => setAddEmailsFormload(true)}>
                                                            <icons.EditWhite />
                                                            <DescriptionText>Enter manually</DescriptionText>
                                                        </LoadBoxOptionInner>
                                                    </LoadBoxOption>
                                                </MiddleSection>
                                            )
                                        }

                                        { addEmailsFormload && (
                                                <MiddleSectionOther>
                                                    
                                                    <AllOfThem ref={emailListBox}>
                                                        {
                                                            inputFields.map((input, index) => {
                                                                return (
                                                                    <InputRow key={index} errorFormatting={input.valid !== 'TBC' ? !input.valid : false}>
                                                                        <OpeningInt>
                                                                            { index + 1 }
                                                                        </OpeningInt>
                                                                        <InputComponent 
                                                                            typeOf={'text'} 
                                                                            placeholder={'Enter another email...'} 
                                                                            readOnly={false} 
                                                                            type={'text'} 
                                                                            name={'email'}
                                                                            value={input.email}
                                                                            tabindex={index}
                                                                            onChange={(event) => handleOnChange(index, event)}
                                                                            onBlur={(event) => checkValidEmail(index, event)}
                                                                            errorFormatting={input.valid !== 'TBC' ? !input.valid : false}
                                                                        />
                                                                        <ValidEmail valid={input.valid !== 'TBC' ? input.valid : true}>
                                                                            { input.valid !== 'TBC' ? (!input.valid ? (`Invalid Email`) : null) : null }
                                                                            { input.valid !== 'TBC' ? ((input.valid && input.email !== '') ? (`Valid Email`) : null) : null }
                                                                        </ValidEmail>
                                                                        <CloseButtonEach onClick={() => removeRow(index)}>
                                                                            <icons.CancelOutlinedStyledDark />
                                                                        </CloseButtonEach>
                                                                    </InputRow>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </AllOfThem>
                                                    <ButtonSave tabindex={inputFields.length + 1} onClick={uploadEmails}>
                                                        Save new emails audiance
                                                    </ButtonSave>
                                                </MiddleSectionOther>
                                            )
                                        }
                                        
                                        
                                    </LeftColumnInner>
                                )}                                
                   
                            </LeftColumn>
                        )}

                        { showAddNewForm  && (

                            <RightSectionForm>

                                <ItemsToShowOriginalRight>
                                    <SectionName>{'Create new audiance'}</SectionName>
                                </ItemsToShowOriginalRight>


                                { generalHelper.length > 0 ? (
                                    <ErrorFieldOuter>
                                        <ErrorField>
                                            { 
                                                generalHelper.map((item) => {
                                                
                                                    
                                                    return (<li>{ item }</li>)
                                                
                                                })
                                            }
                                        </ErrorField>
                                    </ErrorFieldOuter>
                                ) : null }

                                <FormField>
                                    <FormTitles>
                                        <FieldTitle>Name</FieldTitle>
                                    </FormTitles>
                                    <inputs.BasicTextInput 
                                        title={`Name`}
                                        placeholder={``}
                                        ready={!spinnerSwitch}
                                        type={`text`}
                                        bind={bindName} 
                                        value={name}
                                        additionalStyling={`
                                            background:#ffffff;
                                        `}
                                        autoFocusOnMount={true}
                                        symbolRight={null}
                                        errorMessage={nameHelper}
                                        tabIndex={1}
                                        /> 
                                </FormField>
                                

                                <Button type={'submit'} tabIndex={4} disabled={spinnerSwitch} onClick={sendCreateAudiance}>
                                    { !spinnerSwitch ? 'Create' : <materials.Spinner color='#ffffff' size='small'/> }
                                </Button>

                            </RightSectionForm>

                        ) }
                    

                </SectionNext>

        </elements.PageContainer>
    )

}

export default AudianceLists;


const HeadingTitle = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-weight:bold;
`

const DescriptionText = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    color:#ffffff;
    font-size:20px;
`



const InputComponent = styled.input`
    width:100%;
    height:100%;
    border:none;
    outline:none;
    border-radius:5px;
    padding:15px 15px;
    font-size:16px;
    z-index:1010;
    background:none;

    ${props => props.errorFormatting ? `
        color:#FF7F7F;
    ` : null}
`

const ValidEmail = styled.div`
    width:140px;
    color: ${props => props.valid ? 'green' : '#FF7F7F'} ;
    font-weight:bold;
    font-size:13px;
    padding-right:20px;
`

const TopColumnHeading = styled.div`
    padding-top:20px;
`

const AllOfThem = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    width:100%;
    max-height:400px;
    overflow:scroll;
    border:1px solid #e5e5e5;
    border-radius:5px;
    transition:all 0.5s;
`

const LoadBoxOption = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:300px;
    width:100%;
    padding:20px;
`

const LoadBoxOptionInner = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:200px;
    width:200px;
    cursor:pointer;
    border:1px solid #e5e5e5;
    background:#3cb598;
    border-radius:100%;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    transition: all 0.5s;
    position:relative;
    top:0px;

    &:hover {
        top:-10px;
    }
`


const ItemsToShow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    padding:15px 15px;
    padding-bottom:0px;
    border-bottom-left-radius:25px;
    border-bottom-right-radius:25px;
    position:relative;
    top:-5px;
`


const BenefitTitle = styled.div`
    width:100%;
    height:80px;
    box-sizing:border-box;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    text-align:center;
    border-bottom-left-radius:50px;
    border-bottom-right-radius:50px;
    position:relative;
    transition: all 0.5s;
    top: -15px;
   
    
    ${props => !props.active ? `
        background:#ffffff;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
        cursor:pointer;

        &:hover {
            top:0px;
        }
    ` : `
        top: 0px;
    `}
`

const OpeningInt = styled.span`
    display:flex;
    height:100%;
    width:40px;
    align-items:center;
    justify-content:center;
    text-align:right;
    padding-left:10px;
    color:#a9a9a9;

`


const InputRow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    position:relative;
    border-radius:5px;
    width:100%;
    border:none;
    border-left:1px solid transparent;
    border-right:1px solid transparent;
    background:#ffffff;
    border:1px solid transparent;
    ${props => props.errorFormatting ? (`
        background:#FFF2F2;
        border-left:1px solid #FF7F7F;
        border-right:1px solid #FF7F7F;
    `) : null }
`

const CloseButton = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    border-radius:100px;
    height:35px;
    width:35px;
    background:#d5d5d5;
    position:relative;
    transition: all 0.5s;
    cursor:pointer;
    top: 0px;
    z-index:10000;

    &:hover {
        top:-5px;
    }
`


const CloseButtonEach = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    border-radius:100px;
    height:35px;
    width:35px;
    position:relative;
    transition: all 0.5s;
    cursor:pointer;
    top: 0px;
    z-index:10000;
    margin-right:10px;
    transform: rotate(0);

    &:hover {
        transform: rotate(90deg);
    }
`

const UpdateFielder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
`

const TopRow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    width:100%;
    padding:20px;
`

const MiddleSection = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
`

const MiddleSectionOther = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    width:100%;
    height:100%;
`



const SmallText = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius:20px;
    background:#e9e9e9;
    dispaly:block;
    padding:7px 10px;
    font-size:13px;
`

const LeftColumnOriginal = styled.div`
    display:flex;
    flex-direction:column;
    width:400px;
    height:100%;
    padding-bottom:20px;
`

const LeftColumnInnerOriginal = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    background:#ffffff;
    height:100%;
    border-radius:30px;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
`

const ItemsToShowOriginal = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:30px 30px;
`


const ItemsToShowOriginalRight = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:30px 30px;
    padding-top:50px;
`



const ButtonGoTo = styled.button`
    flex-grow:0;
    flex-shrink:0;
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    width:40px;
    height:40px;
    outline:0;
    margin-left:5px;
    cursor:pointer;
    transition: all 0.5s;
    background: ${props => props.active ? '#3cb598' : '#a9a9a9'};
    opacity:${props => props.active ? '1.0' : '0.5' };
    border-radius:5px;
    border: 1px solid transparent;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    font-weight:bold;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`


const ButtonGoToAnother = styled.button`
    flex-grow:0;
    flex-shrink:0;
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    height:40px;
    outline:0;
    margin-left:5px;
    cursor:pointer;
    transition: all 0.5s;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    background: ${props => props.active ? '#3cb598' : '#a9a9a9'};
    opacity:${props => props.active ? '1.0' : '0.5' };
    border-radius:5px;
    border: 1px solid transparent;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    font-weight:bold;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;
    margin-top:20px;
    padding:10px 10px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`


const ButtonGoToAnotherFirst = styled.button`
    flex-grow:0;
    flex-shrink:0;
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    height:40px;
    outline:0;
    margin-left:5px;
    cursor:pointer;
    transition: all 0.5s;
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    background: ${props => props.active ? '#3cb598' : '#a9a9a9'};
    opacity:${props => props.active ? '1.0' : '0.5' };
    border-radius:5px;
    border: 1px solid transparent;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    font-weight:bold;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;
    padding:10px 10px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`

const ProgressBox = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:200px;
    width:100%;
    padding:10px;
`

const InnerProgressBox = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:200px;
    width:100%;
    border:1px solid #a9a9a9;
`



const EditButtons = styled.span`
    flex-grow:1;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    padding-left:20px;
`

const SectionName = styled.span`
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    align-content:center;

`

const BottomOptions = styled.div`
    width:100%;
    border-top:1px solid #e5e5e5;
    padding-top:20px;

`


const InnerTitle = styled.span`
    font-size:20px;
    text-align:center;
    font-family: newFontFace, Arial, sans-serif;
    border-radius:15px;
    padding:15px;
    width:100%;
`


const ErrorFieldOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
`


// #4CD964;
const Button = styled.button`
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 25px 0px;
    outline:0;
    margin-top:20px;
    cursor:pointer;
    transition: all 0.5s;
    width:100%;
    background: #3cb598;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    ${props => !!props.tabIndex ? props.tabindex : null};
    font-weight:bold;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:focus {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


// #4CD964;
const ButtonSave = styled.button`
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 25px 0px;
    outline:0;
    margin-top:20px;
    cursor:pointer;
    transition: all 0.5s;
    width:100%;
    background: #3cb598;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    ${props => !!props.tabIndex ? props.tabindex : null};
    font-weight:bold;
    positon:relative;
    bottom:0px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:focus {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonForSure = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => props.oftype === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const AnotherButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    white-space:nowrap;
    width: 300px;
    align-items:center;
    justify-content:center;
    text-align:center;

`

const ButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    width:50px;
    align-items:center;
    justify-content:center;
    text-align:center;

`


const ErrorField = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:10px;
    font-size:14px;
    border-radius:50px;
    list-style:none;
    border: 1px dashed #F67280;
    background: #ffecee;
    margin-bottom:20px;


    & > li {
        color: red;
        margin-left:5px;
        color:red;
        text-align:center;
        width:100%;

    }
`

const LeftColumn = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    padding-bottom:20px;
    padding-left:20px;
`


const EachMember = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    padding:20px;
    padding-bottom:0px;
    border-radius:20px;
`

const EachMemberNone = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    align-content:center;
    border-radius:20px;
    padding:20px;
    text-align:center;
`


const EachMemberNoneOther = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    align-content:center;
    border-radius:20px;
    padding-top:250px;
`

const EachMemberNoneHorizontal = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    align-content:center;
    padding: 20px;
    border-radius:5px;
    border-bottom:1px dashed #e5e5e5;
`



const EachMemberLeft = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content:flex-start;
    align-items: flex-start;
    align-content:center;
    padding-left:20px;
`
const NameOfMember = styled.div`
    font-size: 16px;
    text-align:left;
    font-weight:bold;
    padding-right:20px;
`

const RoleType = styled.div`
    font-size: 16px;
    text-align:left;
`

const LeftGroupRoleType = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    justify-items:center;

`

const RoleTypeTop = styled.div`
    font-size:20px;
    text-align:left;
    font-weight:bold;
`

const RoleTypeTopLittle = styled.div`
    font-size:18px;
    text-align:left;
`

const GoToSign = styled.div`
    padding-right:5px;
    transition: all 0.3s;
    display:flex;
    justify-content:center;
    align-items: center;
    align-content:center;  
`

const Seperator = styled.div`
    width:100%;
    height: 20px;
`


const EachMemberInner = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    align-content:center;
    border-radius:15px;
    padding:30px;
    cursor: pointer;
    background:#ffffff;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;

    &:hover {
        & > ${GoToSign} {
            padding-right:10px;
        }
    }
`

const EachMemberInnerOther = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    align-content:center;
    border-radius:15px;
    padding:20px 0px;
    cursor: pointer;
    ${props => props.active ? `
        background:#f9f9f9;
        border: 1px solid #e5e5e5;
        & > ${GoToSign} {
            padding-right:10px;
        }
    ` : null}

    &:hover {
        background:#f9f9f9;

        & > ${GoToSign} {
            padding-right:20px;
        }
    }
`

const EachMemberInnerAddNew = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    height:80px;
    border-radius:15px;
    cursor: pointer;
    background:rgb(190, 223, 229);
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`

const LeftColumnInner = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    border-radius:30px;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    padding-top:30px;
`

const RightSectionForm = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    justify-content:flex-start;
    align-items:center;
    height:100%;
    padding:30px;
    padding-top:0px;
`

const RightSection = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    justify-content:flex-start;
    align-items:center;
    height:100%;
    padding:30px;
`

const AnotherSection = styled.div`
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    height:100%;
`


const SectionNext = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    height:100%;
    padding:50px;
    
`

const FormField = styled.div`
    padding:10px 0px;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`

const FormFieldRow = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    width:100%;
`


const FormTitles = styled.span`
    font-size:14px;
    font-weight:500;
    padding-bottom:10px;
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing:border-box;

`
const FieldTitle = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
`


const HeadingTopSection = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    width:100%;
`