import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// ------------
// IMPORT ASSETS

const maxThreshold = 0.7
const minThreshold = 0.3


// const moveAnimation = () => keyframes`
//     0% {
//         -webkit-transform: translateY(0%);
// 			transform: translateY(0%);
//     50% { -webkit-transform: translateY(100%);
//         transform: translateY(100%); }
//     100% { -webkit-transform: translateY(200%);
//         transform: translateY(200%);  }
// `

const GroupContiner = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    z-index:-1;
`

const range = [100, 125, 175, 200];

const Container = styled.div`
    flex-shrink:0;
    flex-grow:0;
    display: flex;
    flex-direction: row;
    padding: 0 1px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    position:relative;
    top:${props => Number(props.top) + `%`};
    left:${props => Number(props.left) + `%`};
    -webkit-transform: translate(${props => props.offset.x}%, ${props => props.offset.y}%);
			transform: translate(${props => props.offset.x}%, ${props => props.offset.y}%);
            transition: transform ${props => props.duration / 2}s linear;
            will-change: transform;
`

const Column = styled.div`
    max-width:${props => props.width};
    padding: 0 2px;
`

const breatheAnimation = (startingOpacity) => keyframes`
    0% { opacity: 0 }
    50% { opacity: ${Number(startingOpacity)} }
    100% { opacity: 0 }
`



// 50% { opacity: ${Number(startingOpacity) - 1} }
const LoanpoolDivisor = styled.div`
    margin-top:4px;
    vertical-align:middle;
    width:${props => props.width}px;
    height:${props => props.height}px;
    background:${props => props.color};
    opacity:0;
    border-top-left-radius:${props => props.borderRad === '1' ? props.borderRadius + 'px' : 0 };
    border-bottom-left-radius:${props => props.borderRad === '2' ? props.borderRadius + 'px' : 0 };
    border-top-right-radius:${props => props.borderRad === '3' ? props.borderRadius + 'px' : 0 };
    border-bottom-right-radius:${props => props.borderRad === '4' ? props.borderRadius + 'px' : 0 };
    cursor:pointer;
    animation-name: ${props => breatheAnimation(props.opacity)};
    animation-duration: ${props => props.duration / 2}s;
    animation-iteration-count: infinite;

`


const Loanpool = ({totalSize, variableProp, variablePIProp, fixedPIProp, color, opacity}) => {

    const [offset, setOffset] = useState({
        x: range[Math.round(Math.random() * 4)],
        y: range[Math.round(Math.random() * 4)]
    })
    

    const duration = Math.max(75, Math.floor(Math.random() * 200));

    
    useEffect(() => {
        setTimeout(() => {
            setOffset({
                x: range[Math.round(Math.random() * 4)],
                y: range[Math.round(Math.random() * 4)]
            })
            
          
            
        }, 1)
       
        // setInterval(() => {
        //     setOffset({
        //         x: range[Math.round(Math.random() * 4)],
        //         y: range[Math.round(Math.random() * 4)]
        //     })
            
        //     console.log('Subsequent Update ', offset);
            
        // }, duration)
    }, [])

    const position = {
        top: Math.floor(Math.random() * 200) - 100,
        left: Math.floor(Math.random() * 200) - 100
    }

    let borderRadius = 0;

    if (totalSize > 100) {
        borderRadius = totalSize / 15
    } else {
        borderRadius = totalSize / 5
    }

    variableProp = Math.max(Math.min(variableProp,maxThreshold), minThreshold);
    variablePIProp = Math.max(Math.min(variablePIProp,maxThreshold), minThreshold);
    fixedPIProp = Math.max(Math.min(fixedPIProp,maxThreshold), minThreshold);

    return (
        <GroupContiner>
            <Container duration={duration} top={position.top} left={position.left} offset={offset}>
                <Column width={variableProp}>
                    <LoanpoolDivisor duration={duration} borderRad='1' borderRadius={borderRadius} opacity={opacity.varPI} color={color.varPI} width={totalSize * variableProp} height={totalSize * variablePIProp} />
                    <LoanpoolDivisor duration={duration} borderRad='2' borderRadius={borderRadius} opacity={opacity.varIO} color={color.varIO} width={totalSize * variableProp} height={totalSize * (1-variablePIProp)} />
                </Column>
                <Column width={1 - variableProp}>
                    <LoanpoolDivisor duration={duration} borderRad='3' borderRadius={borderRadius} opacity={opacity.fixPI} color={color.fixPI} width={totalSize * (1 - variableProp)} height={totalSize * fixedPIProp} />
                    <LoanpoolDivisor duration={duration} borderRad='4' borderRadius={borderRadius} opacity={opacity.fixIO} color={color.fixIO} width={totalSize * (1 - variableProp)} height={totalSize * (1-fixedPIProp)} />
                </Column>
            </Container>
        </GroupContiner>
    )
}

export default Loanpool;