import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import moment from 'moment-timezone';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../state/actions';

import {PDFDownloadLink} from '@react-pdf/renderer';

import { icons, elements, materials } from '../../../../components';

import { format } from '../../../../services';


const AccountBillingBills = (props) => {


    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    const [bills, setBills] = useState([])

    // actions

     const { 
        getBills
    } = bindActionCreators(actions, useDispatch());

   
    useEffect(() => {

        setSpinnerSwitch(true);
        getBills({}, (success, response) => {

            if (success) {
                
                setBills(response);
                setSpinnerSwitch(false);
            }
        })
       
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

        <elements.DataTable 
            loading={spinnerSwitch}
            data={bills}
            fields={[
                    {
                        name: "Date",
                        accessor: "recordDate",
                        minWidth: 70,
                        width:130,
                        display: (value) => {
                            return (<TitleCoverage>
                                    <TitleCoverageNotCenter>{ moment(Number(value)).format("MMM D, YYYY") }</TitleCoverageNotCenter>
                                    <TitleCoverageNotCenter>{ moment(Number(value)).format("[(]h:mma[)]") }</TitleCoverageNotCenter>
                                </TitleCoverage>)
                        }
                    },
                    {
                        name: "Description",
                        accessor: "description",
                        minWidth: 70,
                        flex:1,
                        display: (value) => {
                            return (<TitleCoverage>
                                <TitleCoverageNotCenterOther>{ value.name }</TitleCoverageNotCenterOther>
                                <TitleCoverageNotCenterOther>{ `ID: ${value.id}` }</TitleCoverageNotCenterOther>
                            </TitleCoverage>)
                        }
                    },
                    {
                        name: "Amount",
                        accessor: "amount",
                        minWidth: 70,
                        width:130,
                        display: (value) => {
                            return (<TitleCoverageNotCenter>{ format.numberWithCommas(value.amountToPay, `${value.currency.toUpperCase()} $`, "") }</TitleCoverageNotCenter>)
                        }
                    },

                    {
                        name: "Status",
                        accessor: "status",
                        minWidth: 120,
                        width:120,
                        display: (value) => {
                            return (<TitleCoverageNotCenter>
                                <InternalValues positive={value === 'Paid' ? 'true' : 'false'}>
                                    { value }
                                </InternalValues>
                            </TitleCoverageNotCenter>)
                        }
                    },

                    {
                        name: "Tax Invoice",
                        accessor: "invoiceDetails",
                        minWidth: 70,
                        width:130,
                        display: (value) => {
                            return (<ButtonLinkDownload document={<elements.Invoice invoice={value} fileName={"dylan.pdf"} />}>
                                {({ loading }) => {
                                    return (loading ? <materials.Spinner color='#ffffff' size='small'/> : <icons.CloudDownloadRoundedStyled />)
                                }}
                            </ButtonLinkDownload>)
                        }
                    }
                    
                ]}/>

    )

}

export default AccountBillingBills;


const TitleCoverage = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    text-align:center;
    font-family: newFontFace, Arial, sans-serif;
    font-size:14px;
    padding: 0px;
    width:100%;
    height:100%;

`


const TitleCoverageNotCenter = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    text-align:center;
    font-family: newFontFace, Arial, sans-serif;
    font-size:13px;
    padding: 0px;
    width:100%;
    height:100%;
`

const InternalValues = styled.span`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:7px;
    color:#ffffff;
    background: ${ props => props.positive === 'true' ? 'rgb(60, 181, 152)' : 'rgb(222, 82, 51)'};
    border-radius:50px;
`


const TitleCoverageNotCenterOther = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    text-align:left;
    font-family: newFontFace, Arial, sans-serif;
    font-size:13px;
    padding: 0px;
    width:100%;
    height:100%;
`


const ButtonLinkDownload = styled(PDFDownloadLink)`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    text-decoration:none;
    background: ${props => props.type === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '0.5'};

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        opacity:1.0;
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`
