
import axios from 'axios';
import { JSONResponseToConsole, authHeader, signout } from '../index';

import {
    LOAD_DISPLAY_PICTURE
}  from '../types';


import { 
    ROUTE_IMG_UPLOAD,
    ROUTE_IMG_GET
} from '../routes';



// ---------------------------
// MESSAGE / GET CONTACTS

export const upload = (formProps, callback) => async dispatch => {

    try {
        // , 'content-type': 'multipart/form-data'
        // Pick up the groceries
        const response = await axios.post(ROUTE_IMG_UPLOAD, formProps, { headers: {  ...authHeader(), 'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryC5ap0qgqQ1DtUsOt' }});
        
        JSONResponseToConsole(`POST TO ${ROUTE_IMG_UPLOAD} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        const { displayPicture } = payload;

        dispatch({ type: LOAD_DISPLAY_PICTURE, payload: displayPicture });

        callback(success, payload);
        
    } catch (e) {

        console.log(e);
        signout();

    }

}


export const getImage = (urlToUse, callback) => async dispatch => {

    try {
        // Pick up the groceries
        const response = await axios.get(`${ROUTE_IMG_GET}/${urlToUse}`, { headers: { ...authHeader() }, responseType: 'arraybuffer' }); // previously blob

        JSONResponseToConsole(`GET TO ${ROUTE_IMG_GET}/${urlToUse} endpoint`, response);
        
        const arrayBuffer = response.data;
        const uint8Array = new Uint8Array(arrayBuffer);
        let binary = '';
        for (let i = 0; i < uint8Array.length; i++) {
            binary += String.fromCharCode(uint8Array[i]);
        }
        const imageBase64 = btoa(binary);
       
        const imageDataUrl = `data:image/jpeg;base64,${imageBase64}`;

        // Unpack the groceries
        callback(true, imageDataUrl);
        
    } catch (e) {
        console.log(e);
        signout();

    }

}