import React from 'react';
import styled from 'styled-components';


const BenefitsReel = ({ title }) => {
    return (

        <Container addSidePadding={true} shadow={false}>
            <MainTitle>{title}</MainTitle>
        </Container>

    );
}


export default BenefitsReel;


const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    padding-bottom:50px;
    min-width:500px;
    padding-left:${props => props.addSidePadding ? 50 : 0 }px;
    padding-right:${props => props.addSidePadding ? 50 : 0 }px;
    ${props => {
        if (props.shadow === 'below') {
            return (`box-shadow: rgba(249, 249, 249, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;`)
        } else if (props.shadow === 'above') {
            return (`box-shadow: rgba(249, 249, 249, 0.12) 0px -9px 14px 0px, rgba(0, 0, 0, 0.12) 0px -5px 6px 0px;`)
        }
    }}
    
`

const MainTitle = styled.span`
    width:100%;
    text-align:center;
    font-size:20px;
    font-family: newFontFaceLight, Arial, sans-serif;
    box-sizing:border-box;
`

