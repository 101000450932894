import axios from 'axios';
import { JSONResponseToConsole, authHeader, signout } from '../index';

import {
    AUTH_ERROR
}  from '../types';

import { 
    CONNECTION_TO_API_NOT_WORKING,
    INCORRECT_CREDENTIALS
} from '../errors';

import { 
    ROUTE_ACCOUNT_EXISTS,
    ROUTE_ACCOUNT_ORDER_NEWADDRESS,
    ROUTE_ACCOUNT_ORDER_EXISTINGADDRESS,
    ROUTE_ACCOUNT_UPDATE,
    ROUTE_ACCOUNT_GET_DETAILS
} from '../routes';


const handleServerErrors = (dispatch, callback, e) => {


    let message = '';

    if (e.response && e.response.status === 401) {
        message = INCORRECT_CREDENTIALS.description;
    } else {
        message = CONNECTION_TO_API_NOT_WORKING.description
    }

    dispatch({ type: AUTH_ERROR, payload: message });
    callback(false, message, true);

}


export const update = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_ACCOUNT_UPDATE, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_ACCOUNT_UPDATE} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }

}


export const getDetails = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_ACCOUNT_GET_DETAILS, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_ACCOUNT_GET_DETAILS} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

    }

}











// ---------------------------
// SIGN UP

export const accountExists = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_ACCOUNT_EXISTS, formProps);

        JSONResponseToConsole(`POST TO ${ROUTE_ACCOUNT_EXISTS} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        console.log(e);
        signout();

    }

}




export const addNewAddress = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_ACCOUNT_ORDER_NEWADDRESS, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_ACCOUNT_ORDER_NEWADDRESS} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        console.log(e);
        signout();

    }

}




export const addExistingAddress = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_ACCOUNT_ORDER_EXISTINGADDRESS, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_ACCOUNT_ORDER_EXISTINGADDRESS} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        console.log(e);
        signout();

    }

}
