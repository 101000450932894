import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spinner = (props) => {

    return (

            <SpinnerObject color={props.color} size={props.size}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </SpinnerObject>

    )

}

export default Spinner;




const loadingSpinnerAnnimation_ONE = () => keyframes`
    
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }

`

const loadingSpinnerAnnimation_TWO = (translation) => keyframes`
    
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(${translation}, 0);
    }

`


const loadingSpinnerAnnimation_THREE = () => keyframes`
    
    0% {
        transform: scale(1);
    }
    
    100% {
        transform: scale(0);
    }

`

const SpinnerObject = styled.div`

    display: inline-block;
    position: relative;
    width: ${props => props.size === 'small' ? '15px': '15px' };
    height: ${props => props.size === 'small' ? '15px': '15px' };
    left:-10px;

    & > div {
        position: absolute;
        top: ${props => props.size === 'small' ? '9px': '9px' };
        width: ${props => props.size === 'small' ? '7px': '13px' };
        height: ${props => props.size === 'small' ? '7px': '13px' };
        border-radius: 50%;
        background: ${props => props.color};
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
        border:${props => props.size === 'small' ? '1px': '0px' } solid ${props => props.color};
        
    }

    & > div:nth-child(1) {
        left: ${props => props.size === 'small' ? '4px': '8px' };
        animation: ${loadingSpinnerAnnimation_ONE()} 0.6s infinite;
    }

    & > div:nth-child(2) {
        left: ${props => props.size === 'small' ? '4px': '8px' };
        animation: ${ props => props.size === 'small' ? 
            loadingSpinnerAnnimation_TWO('12px') : 
            loadingSpinnerAnnimation_TWO('24px') } 0.6s infinite;
    }

    & > div:nth-child(3) {
        left: ${props => props.size === 'small' ? '16px': '32px' };
        animation: ${ props => props.size === 'small' ? 
            loadingSpinnerAnnimation_TWO('12px') : 
            loadingSpinnerAnnimation_TWO('24px') } 0.6s infinite;
    }

    & > div:nth-child(4) {
        left: ${props => props.size === 'small' ? '28px': '56px' };
        animation: ${loadingSpinnerAnnimation_THREE()} 0.6s infinite;
    }

`;
