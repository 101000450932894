import * as nav from './groups/nav';
import * as auth from './groups/auth';

import * as message from './groups/message';
import * as account from './groups/account';

import * as img from './groups/img';
import * as pay from './groups/pay';
import * as subscriptions from './groups/subscriptions';
import * as products from './groups/products';
import * as audiances from './groups/audiances';
import * as designs from './groups/designs';
import * as wedding from './groups/wedding';



import { config } from '../../constants/config';


export const authHeader = () => {
    const token = localStorage.getItem('token');

    return {
        'Authorisation': token
    }
    
}

// -----------------
// NAV ACTIONS
export const updateSticky = nav.updateSticky;
export const updateShadow = nav.updateShadow;
export const updateMenu = nav.updateMenu;
export const updatePageRef = nav.updatePageRef;


// -----------------
// AUTHENTICATION ACTIONS
export const signup = auth.signup;
export const login = auth.login;
export const reset = auth.reset;
export const signout = auth.signout;
export const forgot = auth.forgot;
export const rsvp = auth.RSVP;


// Wedding details
export const getInfo = wedding.getInfo;
export const updateFlightDetail = wedding.updateFlightDetail;
export const updateStayDetail = wedding.updateStayDetail;


// -----------------
// ACCOUNT ACTIONS
export const accountUpdate = account.update;
export const getAccountDetails = account.getDetails;
// --- 
export const accountExists = account.accountExists;
export const addNewAddress = account.addNewAddress;
export const addExistingAddress = account.addExistingAddress;


// -----------------
// SUBSCRIPTION ACTIONS
export const cancelSubscribeToPremium = subscriptions.cancelSubscribeToPremium;
export const subscribeToPremium = subscriptions.subscribeToPremium;
export const getPlan = subscriptions.getPlan;

// -----------------
// SUBSCRIPTIONS
export const getBills = subscriptions.getBills;

// -----------------
// MESSAGING ACTIONS
export const sendMessage = message.sendMessage;
export const getMessageContacts = message.getContacts;
export const getMessageConversations = message.getConversations;
export const getMessages = message.getMessages;


// -----------------
// PAYMENT ACTIONS
export const getCustomer = pay.getCustomer;
export const getPaymentMethods = pay.getPaymentMethods;
export const deleteCard = pay.deleteCard;
export const defaultCard = pay.defaultCard;


//

// -----------------
// IMAGE ASSET ROUTES
export const uploadImage = img.upload;
export const getImage = img.getImage;


// PRODUCT ACTIONS
export const getProductList = products.getProducts;
export const activateProduct = products.activateProduct;
export const linkAudianceToProduct = products.linkAudiance;
export const delinkAudianceToProduct = products.delinkAudiance;

// AUDIANCE ACTIONS
export const getAudiances = audiances.getAudiances;
export const createAudiance = audiances.createAudiance;
export const addContactsToAudiance = audiances.addContactsToAudiance;
export const getContactsFromAudiance = audiances.getContactsFromAudiance;
export const removeContactFromAudiance = audiances.removeContactFromAudiance;


// EMAIL TEMPLATE ACTIONS
export const getEmailDesigns = designs.getAllDesigns;
export const createDesign = designs.createDesign;



// ----------------
// Multiple Action creation function

export const executeActions = (All_Action_Payloads, callback) => async dispatch => {

    All_Action_Payloads.forEach(Individual_Action_Payload => {
        const { action, payload } = Individual_Action_Payload;
        dispatch({ type: action, payload: payload });
    });
    
    callback();

} 

export const JSONResponseToConsole = (label, json) => {

    if (config.TESTING_FUNCTIONS) {
        console.log(label, ' ', json);
    }
    
}