// ---------------------------------------
// ---------------------------------------
//
// *** Warning ***
//
// ALL VARIABLES HERE ARE NOT PRIVATE
// 
// ---------------------------------------
// ---------------------------------------


// Production variables
// -------------------------

const production = {
    SERVER_API_URL: 'https://api.rachelanddylan.com',
    CLIENT_URL: 'https://rachelanddylan.com',
    TESTING_FUNCTIONS: false,
    STRIPE_PUBLISHABLE_KEY: "XXX",
    GOOGLE_MAPS_API_KEY: "XXX"
};


// Development variables
// -------------------------

const development = {
    SERVER_API_URL: 'https://dev-api.rachelanddylan.com',
    CLIENT_URL: 'https://dev.rachelanddylan.com',
    TESTING_FUNCTIONS: true,
    STRIPE_PUBLISHABLE_KEY: "pk_test_51HZpQuL6SbJ83fW2G5Q7siyiYaeWoA60KicAntnRckHHpcPiTgjDgsv5MBIkZFE8lMB75IR1fkImu7xKLX6vh2Be00BCB6MoTy",
    GOOGLE_MAPS_API_KEY: "XXX"
}


// Development variables
// -------------------------

const local = {
    SERVER_API_URL: 'http://localhost:3090',
    CLIENT_URL: 'http://localhost:3000',
    TESTING_FUNCTIONS: true,
    STRIPE_PUBLISHABLE_KEY: "pk_test_51HZpQuL6SbJ83fW2G5Q7siyiYaeWoA60KicAntnRckHHpcPiTgjDgsv5MBIkZFE8lMB75IR1fkImu7xKLX6vh2Be00BCB6MoTy",
    GOOGLE_MAPS_API_KEY: "XXX"
}


const url = new URL(window.location.href);
const parts = url.hostname.split('.');
const subdomain = parts.length > 2 ? parts[0] : '';

export const config = process.env.NODE_ENV === 'development' ? local : subdomain === 'dev' ? development : production;