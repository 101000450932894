import styled from 'styled-components';

import { 
    FormatListBulletedRounded, 
    BarChart,
    AssistantPhotoRounded, 
    ChevronRight,  
    Message,
    ExitToApp, 
    Search,
    WarningSharp,
    Send,
    CloudDownloadRounded,
    ArrowBack, 
    ArrowForward,
    Clear, 
    CreditCardRounded,
    Done,
    Person,
    SecurityRounded,
    Add,
    HomeOutlined,
    RepeatOutlined,
    EmailOutlined,
    BrushOutlined,
    CloudUploadOutlined,
    Edit,
    BlurOn,
    KeyboardArrowDownRounded,
    RestaurantRounded,
    KeyboardArrowUpRounded
} from '@material-ui/icons';

// ICON STYLES

const BarChartStyled = styled(BarChart)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`



const RestaurantRoundedStyled = styled(RestaurantRounded)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
    }
`




const SearchStyled = styled(Search)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s; 
    }
`

const AssistantStyled = styled(AssistantPhotoRounded)`
    && {
        fill: #090909;
        font-size:20px;
    }
`

const BlurOnStyled = styled(BlurOn)`
    && {
        fill: #090909;
        font-size:20px;
    }
`

const HomeStyled = styled(HomeOutlined)`
    && {
        fill: #ffffff;
        font-size:20px;
    }
`

const MessageStyled = styled(Message)`
    && {
        fill: #090909;
        font-size:20px;
    }
`

const MessageStyledWhite = styled(Message)`
    && {
        fill: #FFFFFF;
        font-size:20px;
    }
`

const UploadWhite = styled(CloudUploadOutlined)`
    && {
        fill: #FFFFFF;
        font-size:40px;
    }
`

const EditWhite = styled(Edit)`
    && {
        fill: #FFFFFF;
        font-size:40px;
    }
`

const ExitToAppStyled = styled(ExitToApp)`
    && {
        fill: #090909;
        font-size:20px;
    }
`

const ChevronRightStyled = styled(ChevronRight)`
    && {
        fill: #090909;
        font-size:40px;
    }
`

const ChevronRightBlueStyled = styled(ChevronRight)`
    && {
        fill: rgb(0, 146, 255);
        font-size:40px;
    }
`




const ArrowDownRoundedStyled = styled(KeyboardArrowDownRounded)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
        
    }
`


const ArrowUpRoundedStyled = styled(KeyboardArrowUpRounded)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
        
    }
`

const FormatListBulletedRoundedStyled = styled(FormatListBulletedRounded)`
    && {
        fill: #ffffff;
        width: 25px;
        height: 25px;
        transition: all 0.2s;
        
    }
`

const ErrorSymbol = styled(WarningSharp)`
    && {
        fill: red;
        font-size: 20px;
        transition: all 0.5s;
    }
`

const SendStyled = styled(Send)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`


const EmailStyled = styled(EmailOutlined)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`

const BrushStyled = styled(BrushOutlined)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`


const RepeatStyled = styled(RepeatOutlined)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`





const CloudDownloadRoundedStyled = styled(CloudDownloadRounded)`
    && {
        fill: #ffffff;
        font-size:20px;
        transition: all 0.2s;
    }
`


const CreditCardRoundedStyled = styled(CreditCardRounded)`
    && {
        fill: ${props => props.active === 'true' ? `#ffffff` : `#090909`};
        font-size:25px;
        transition: all 0.2s;
    }
`

const AddStyledWhite = styled(Add)`
    && {
        fill: #ffffff;
        font-size:25px;
    }
`


const CancelOutlinedStyled = styled(Clear)`
    && {
        fill:rgb(222, 82, 51);
        font-size:20px;
        transition: all 0.2s;
    }
`

const CancelOutlinedStyledWhite = styled(Clear)`
    && {
        fill:#090909;
        font-size:20px;
        transition: all 0.2s;
    }
`



const CancelOutlinedStyledDark = styled(Clear)`
    && {
        fill: #090909;
        opacity:0.5;
        font-size:20px;
        transition: all 0.2s;

        &:hover {
            opacity:0.9;
        }

        &:active {
            opacity:1.0;
        }
    }
`

const ArrowBackStyled = styled(ArrowBack)`
    && {
        fill: #000000;
        font-size:20px;
        transition: all 0.2s;
    }
`

const ArrowForwardStyled = styled(ArrowForward)`
    && {
        fill: #ffffff;
        font-size:20px;
        transition: all 0.2s;
    }
`

const DoneStyled = styled(Done)`
    && {
        fill: green;
        font-size:20px;
        transition: all 0.2s;
    }
`

const ClearStyledRed = styled(Clear)`
    && {
        fill: red;
        font-size:20px;
        transition: all 0.2s;
    }
`


const PersonStyled = styled(Person)`
    && {
        fill: ${props => props.active === 'true' ? `#ffffff` : `#090909`};
        font-size:25px;
        transition: all 0.2s;
    }
`


const SecurityRoundedStyled = styled(SecurityRounded)`
    && {
        fill: ${props => props.active === 'true' ? `#ffffff` : `#090909`};
        font-size:25px;
        transition: all 0.2s;
    }
`







// EXPORTS

export {
    FormatListBulletedRoundedStyled,
    ChevronRightStyled,
    ExitToAppStyled,
    MessageStyled,
    AssistantStyled,
    SearchStyled,
    BarChartStyled,
    ErrorSymbol,
    CancelOutlinedStyledWhite,
    SendStyled,
    HomeStyled,
    CloudDownloadRoundedStyled,
    CreditCardRoundedStyled,
    CancelOutlinedStyled,
    ArrowBackStyled,
    MessageStyledWhite,
    DoneStyled,
    ClearStyledRed,
    PersonStyled,
    ArrowForwardStyled,
    SecurityRoundedStyled,
    AddStyledWhite,
    EmailStyled,
    RepeatStyled,
    BrushStyled,
    UploadWhite,
    EditWhite,
    CancelOutlinedStyledDark,
    BlurOnStyled,
    ChevronRightBlueStyled,
    ArrowUpRoundedStyled,
    ArrowDownRoundedStyled,
    RestaurantRoundedStyled
}