import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../state/actions';

import moment from 'moment-timezone';

import defaults from '../../constants/defaults';

import { icons, elements, inputs, materials } from '../../components';

import * as assets from '../../assets';

// Get from query

const activeConversation = {
    id: 'XYZ',
    title: 'Rachiebbyxoxo',
    snippet: {
        author: 'You',
        message: 'This is a snippet',
        lastDate: '4w',
        unread: true
    },
    activeNow: true,
    lastActive: new Date(),
    displayPicture: assets.LogoFull
}



const Messages = (props) => {
    

    const { convoID } = props.match.params;

    // Settings
    const [currentConvoID, setCurrentConvoID] = useState(convoID);
    const [convoLoadingResults, setConvoLoadingResults] = useState(false);
    const [conversationList, setConversationList] = useState([]);
    const [activeMessages, setActiveMessages] = useState([]);

    const [messagesLoadingResults, setMessagesLoadingResults] = useState(false);

    // actions

    const { 
        getMessages, 
        getMessageConversations 
    } = bindActionCreators(actions, useDispatch());


    // get conversations
    useEffect( () => {


        setConvoLoadingResults(true);

        getMessageConversations({}, (success, payload) => {

            if (!success) return;

            const compiledResult = payload.convos.map((resultItem) => {
                return {
                        id: resultItem.readableID,
                        title: resultItem.readableID,
                        snippet: {
                            author: 'You',
                            message: 'This is a snippet',
                            lastDate: '4w',
                            unread: true
                        },
                        activeNow: true,
                        displayPicture: assets.LogoFull
                    }
            });

            setConversationList(compiledResult);

            setConvoLoadingResults(false);

            if (convoID === undefined) {
                setCurrentConvoID(compiledResult[0].id);
                props.history.push(`/messages/${compiledResult[0].id}`);
            }

        });


        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentConvoID]);


    const getAllMessages = () => {

        if (currentConvoID === 'new') {
            return setActiveMessages([]);
        } else {

            setMessagesLoadingResults(true);

            getMessages({ conversationID: currentConvoID }, (success, payload) => {

                if (!success) return;

                if (!payload.validConversation) {
                    return updateCurrentConvoID('new');
                }

                const compiledResult = payload.messagesList.map((resultItem) => {
                    return {
                        message: resultItem.message,
                        tip: moment.utc(resultItem.timestamp).tz('Australia/Sydney').format('DD MMM YY h:mm A'),
                        own: resultItem.own
                    }
                });

                

                setActiveMessages(compiledResult);

                setMessagesLoadingResults(false);

            });

        }

    }



    // get messages
    useEffect( () => {

        getAllMessages();

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentConvoID]);



    // Go between different convos
    const updateCurrentConvoID = (convo) => {
        setCurrentConvoID(convo);
        props.history.push(`/messages/${convo}`);

    }


    /// Send a new message
    const messageSent = (payload) => {

        const { readableID } = payload;

        if (!!readableID) {
            return props.history.push(`/messages/new`);
        }

        updateCurrentConvoID(readableID);

        getAllMessages();

    }

   

    return (
        <elements.PageContainer 
            pageReady={true}
            pageTitle={defaults.tabbar.GENERAL}
            pageRef={`messages`}
            sticky={false}
            cover={false}
            menu={'access'}
            topPadding={`60px`}
        >
    
                <SectionNext>

                    <ConversationList>
                        <ConversationPanelHead>

                            <ListOfButtons>
                                <EachButtonElement active={currentConvoID === 'new'} onClick={() => updateCurrentConvoID('new')}>
                                    <icons.AddStyledWhite />
                                    <InnerSetting>Add new support request</InnerSetting>
                                </EachButtonElement>
                                
                            </ListOfButtons>

                            

                            

                        </ConversationPanelHead>
                        <Conversations>

                            {
                                currentConvoID === 'new' &&
                                <NewMessagePlaceholder>
                                    <NewMessagePlaceholderInner>
                                        <ConversationTitle>Creating new support request</ConversationTitle>
                                    </NewMessagePlaceholderInner>
                                </NewMessagePlaceholder>
                            }

                            {
                                convoLoadingResults ? 
                                    
                                    (
                                        <CenterSpinner fadeIn={true}>
                                            <materials.Spinner color={`#a9a9a9`} size={`small`}/>
                                        </CenterSpinner>
                                    )
                                    
                                :

                                    (
                                        conversationList.length === 0 ?

                                        (
                                            <CenterSpinner fadeIn={false}>
                                                <InnerTextElement>No more results</InnerTextElement>
                                            </CenterSpinner>
                                        )

                                        :

                                        conversationList.map((convo) => {

                                            const isConvoInFocus = currentConvoID === convo.id;

                                            return (
                                                <materials.Conversation 
                                                    key={convo.id}
                                                    conversationID={convo.id}
                                                    conversationInFocus={isConvoInFocus}
                                                    activeMember={convo.activeNow}
                                                    displayPicture={convo.displayPicture}
                                                    title={convo.title}
                                                    snippet = {{
                                                        author: convo.snippet.author,
                                                        message: convo.snippet.message,
                                                        lastDate: convo.snippet.lastDate,
                                                        unread: convo.snippet.unread
                                                    }}
                                                    activateConvoID={updateCurrentConvoID}
                                                />
                                            )
                                        })
                                    )

                                
                            }

                        </Conversations>
                    </ConversationList>

                    <MessagePanel>
                        <MessagePanelHead>
                                <ConversationInner>
                                    {
                                        currentConvoID === 'new' ?
                                        (
                                            <>
                                                <DisplaySnippet>
                                                    <ConversationTitle>To: Loanpool support team</ConversationTitle>
                                                    <Snippet>Send us a message and we'll be in touch ASAP</Snippet>
                                                </DisplaySnippet>
                                            </>
                                        ) :
                                        (
                                            <>
                                                <DisplayPictureContainer>
                                                    <DisplayPicture src={activeConversation.displayPicture} />
                                                </DisplayPictureContainer>
                                                <DisplaySnippet>
                                                    <ConversationTitle>{activeConversation.title}{currentConvoID}</ConversationTitle>
                                                    <Snippet>
                                                        {
                                                            activeConversation.activeNow ? 
                                                                ( `Active now` ) :
                                                                ( `Active ${activeConversation.lastActive}` )
                                                        }
                                                    </Snippet>
                                                </DisplaySnippet>
                                            </>
                                        )
                                    }
                                    
                                </ConversationInner>
                        </MessagePanelHead>
                        <ConversationPanel>

                            {
                                messagesLoadingResults &&
                                activeMessages.map((copy, index) => {
                                    return (
                                        <materials.Message 
                                            key={index}
                                            own={copy.own} 
                                            tip={copy.tip}>
                                            
                                            {copy.message}

                                        </materials.Message>
                                    )
                                })
                            }

                        </ConversationPanel>

                        <inputs.SendMessage conversationID={convoID} callback={messageSent}/>

                    </MessagePanel>
                    <QuickBar show={false} />
                   
                </SectionNext>

        </elements.PageContainer>
    )

}

export default Messages;



const CenterSpinner = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding-top:30px;
    padding-bottom:30px;
    text-align:center;

    ${props => !props.fadeIn ? null :
        css`
            -webkit-animation-name: ${fadeIn};
            -webkit-animation-duration: 0.5s;
            animation-name: ${fadeIn};
            animation-duration: 0.5s;
        `
    }

`


const ConversationList = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:500px;
    height:100%;
    border-right:1px solid #e5e5e5;
    z-index:1005;
    background:#ffffff;
`


const Conversations = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:100%;
    overflow:scroll;
`

const InnerTextElement = styled.span`
    color:#a9a9a9;
`


const DisplayPictureContainer = styled.div`
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    justify-content:center;
    align-items:center;
    width:80px;
    height:100%;
    position:relative;
`

const DisplayPicture = styled.img`
    display:flex;
    justify-content:center;
    align-items:center;
    height:55px;
    width:55px;
    border-radius:100px;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
`

const DisplaySnippet = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    width:100%;
    height:100%;
`

const ConversationTitle = styled.span`
    font-weight:600;
    display:flex;
    font-size:14px;
    text-align:center;
`

const Snippet = styled.span`
    font-weight:${props => props.unread ? '600' : '200'};
    display:flex;
    font-size:12px;
    color: ${props => props.unread ? 'blue' : '#000000'};
    padding-top:5px;
`


const QuickBar = styled.div`
    display:${props => props.show ? 'flex' : 'none'};
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:500px;
    height:100%;
    border-left:1px solid #e5e5e5;
    z-index:1005;
    background:#ffffff;
`



const ConversationPanel = styled.div`
    display:flex;
    flex-direction:column-reverse;
    justify-content:flex-start;
    align-items:center;
    height:100%;
    width:100%;
    z-index:1000;
    overflow:scroll;
`



const ListOfButtons = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;

`

const EachButtonElement = styled.button`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:10px;
    border-radius:5px;
    opacity: ${props => props.active ? '0.9' : '0.5'};
    background: #3cb598;
    border:none;
    outline:none;
    cursor:pointer;
    transition: all 1s;
    
    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        opacity: 1.0;
    }
    
    &:focus {
        transition: all 0.3s;
        transform: scale(0.95);
        opacity: 1.0;
    }
    
    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
        opacity: 1.0;
    
    
`






const NewMessagePlaceholderInner = styled.div`
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    text-align:center;
    width:100%;
    height:100%;
    border-radius:10px;
    transition: all 0.3s;
    background: #ebf4ff;
`


const NewMessagePlaceholder = styled.div`
    height:100px;
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    cursor:pointer;
    overflow:hidden;
    padding:10px;
`



const ConversationInner = styled.div`
    display:flex;
    flex-grow:0;
    flex-shrink:0;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:100%;
    padding:30px;
`


const MessagePanel = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:100%;
    z-index:1000;
`

const MessagePanelHead = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    height:100px;
    width:100%;
    z-index:70000;
    border-bottom:1px solid #e5e5e5;
    box-shadow: rgba(229, 229, 229, 0.8) 0px 4px 10px 0px, rgba(229, 229, 229, 0.8) 0px 1px 10px 0px;
    flex-grow:0;
    flex-shrink:0;
    background:#ffffff;
`

const fadeIn = keyframes`
    from {opacity: .4}
    to {opacity: 1}
`



const ConversationPanelHead = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:100px;
    width:100%;
    z-index:1001;
    border-bottom:1px solid #e5e5e5;
    flex-grow:0;
    flex-shrink:0;
    padding:15px;
`

const InnerSetting = styled.div`
    z-index:1001;
    color:#a9a9a9;
    flex-grow:0;
    flex-shrink:0;
    color:#ffffff;

`

const SectionNext = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
`
