import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../state/actions';


import { icons, materials } from '../../../../components';


const AccountBillingPlan = () => {


    const [spinnerSwitch, setSpinnerSwitch] = useState(true);


    const [premiumActive, setPremiumActive] = useState(false);

    // actions

     const { 
        getPlan,
        subscribeToPremium,
        cancelSubscribeToPremium,
    } = bindActionCreators(actions, useDispatch());

    // Helpers 


    useEffect(() => {
        setSpinnerSwitch(true);
        getPlan({}, (success, response) => {
            if (success) {
                if (response.subscription) {
                    if (response.name === 'Premium') {
                        setPremiumActive(true)
                    } else {
                        setPremiumActive(false)
                    }
                }
                
            }
            setSpinnerSwitch(false);
        })

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const subscribeToPremiumNow = () => {
        setSpinnerSwitch(true);
        subscribeToPremium({}, (success, response) => {
            if (success) {
                if (response.ready) {
                    if (response.subscription) {
                        if (response.name === 'Premium') {
                            setPremiumActive(true)
                        } else {
                            setPremiumActive(false)
                        }
                    }
                }
            }
            setSpinnerSwitch(false);
        })
        
    }

    const cancelSubscribeToPremiumNow = () => {
        setSpinnerSwitch(true);
        cancelSubscribeToPremium({}, (success, response) => {
            if (success) {
                if (!response.subscription) {
                        setPremiumActive(false)
                }
            }
            setSpinnerSwitch(false);
        })
    }

    return (

        <DisplayPlanSpacing>
            <DisplayPlanOuter>
                <PlanTitleTop>{ spinnerSwitch ? null : ( !premiumActive ? <PlanTitleTopInner>Active</PlanTitleTopInner> : '' ) }</PlanTitleTop>
                <DisplayPlan>
                    <PlanTitleSection>
                        <PlanTitle>Basic</PlanTitle>
                        <PlanTitlePrice>Free</PlanTitlePrice>
                        <PlanTitle>per month</PlanTitle>
                    </PlanTitleSection>
                    <BenefitsList>
                        <BenefitsListItems>
                            <icons.DoneStyled />
                            <BenefitsListText>First</BenefitsListText>
                        </BenefitsListItems>
                        <BenefitsListItems>
                            <icons.DoneStyled />
                            <BenefitsListText>Second</BenefitsListText>
                        </BenefitsListItems>
                        <BenefitsListItems>
                            <icons.DoneStyled />
                            <BenefitsListText>Third</BenefitsListText>
                        </BenefitsListItems>
                        <BenefitsListItems>
                            <icons.ClearStyledRed />
                            <BenefitsListText>Forth</BenefitsListText>
                        </BenefitsListItems>
                        <BenefitsListItems>
                            <icons.ClearStyledRed />
                            <BenefitsListText>Fifth</BenefitsListText>
                        </BenefitsListItems>
                    </BenefitsList>
                    <BottomPart></BottomPart>
                </DisplayPlan>
            </DisplayPlanOuter>
            <DisplayPlanOuter>
                <PlanTitleTop>{ spinnerSwitch ? null : ( premiumActive ? <PlanTitleTopInner>Active</PlanTitleTopInner> : '' )}</PlanTitleTop>
                <DisplayPlanPremium active={ premiumActive ? 'true' : 'false'}>
                    <PlanTitleSection>
                        <PlanTitleSpecial>Pro</PlanTitleSpecial>
                        <PlanTitlePrice>$9.99</PlanTitlePrice>
                        <PlanTitle>per month</PlanTitle>
                    </PlanTitleSection>
                    <BenefitsList>
                        <BenefitsListItems>
                            <icons.DoneStyled />
                            <BenefitsListText>First</BenefitsListText>
                        </BenefitsListItems>
                        <BenefitsListItems>
                            <icons.DoneStyled />
                            <BenefitsListText>Second</BenefitsListText>
                        </BenefitsListItems>
                        <BenefitsListItems>
                            <icons.DoneStyled />
                            <BenefitsListText>Third</BenefitsListText>
                        </BenefitsListItems>
                        <BenefitsListItems>
                            <icons.DoneStyled />
                            <BenefitsListText>Forth</BenefitsListText>
                        </BenefitsListItems>
                        <BenefitsListItems>
                            <icons.DoneStyled />
                            <BenefitsListText>Fifth</BenefitsListText>
                        </BenefitsListItems>
                    </BenefitsList>
                    <BottomPart>
                        {
                            spinnerSwitch ? (
                                <materials.Spinner color='#a9a9a9' size='small'/>
                            ) : (
                                premiumActive ? (
                                    <ButtonClosure active={'true'} onClick={cancelSubscribeToPremiumNow}>Cancel</ButtonClosure>
                                ) : (
                                    <ButtonWhite active={'true'} onClick={subscribeToPremiumNow}>Activate</ButtonWhite>
                                )
                            )
                        }
                        
                    </BottomPart>
                </DisplayPlanPremium>
            </DisplayPlanOuter>
        </DisplayPlanSpacing>
        
    )

}

export default AccountBillingPlan;


const DisplayPlan = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:300px;
    height:400px;
    border-radius:10px;
    padding-top:30px;
    border: 1px solid #e5e5e5;
    background: #f5fafb;
    color: #000000;
    box-shadow: rgba(229, 229, 229, 0.5) 0px 4px 5px 0px, rgba(229, 229, 229, 0.5) 0px 1px 5px 0px;
    transition: all 0.5s;

    &:hover {
        margin-top: -4px;
    }
`


const DisplayPlanPremium = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:300px;
    height:400px;
    border-radius:10px;
    padding-top:30px;
    border: ${props => props.active === 'true' ? '3px solid green' : '3px solid rgb(93, 138, 168)'};
    background: ${props => props.active === 'true' ? '#e6ffe6' : '#f5fafb'};
    color: #000000;
    box-shadow: rgba(229, 229, 229, 0.5) 0px 4px 5px 0px, rgba(229, 229, 229, 0.5) 0px 1px 5px 0px;
    transition: all 0.5s;

    &:hover {
        margin-top: -4px;
    }
`

const PlanTitleTop = styled.div`
    display:flex;
    height:40px;
    justify-content:center;
    align-items:flex-start;
    color:green;

`

const PlanTitleTopInner = styled.span`
    display:flex;
    padding:5px 20px;
    background:green;
    color:#ffffff;
    border-radius:20px;
`


const DisplayPlanOuter = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`

const DisplayPlanSpacing = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:center;
    width:100%;
`


const BottomPart = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100px;
    color:#a9a9a9;
`


const BenefitsList = styled.ul`
    height:130px;
    width:100%;
    display:flex;
    flex-direction:column;
    justifyContent:center;
    alignItems:center;
    padding:0px 30px;
`

const BenefitsListItems = styled.li`
    font-size:13px;
    width:100%;
    display:flex;
    flex-direction:row;
    padding: 3px 0px;
`

const BenefitsListText = styled.span`
    font-size:13px;
    width:100%;
    display:flex;
    flex-direction:row;
    padding: 0px 5px;
`

const PlanTitleSection = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:20px 0px;
`



const PlanTitle = styled.div`
    display:flex;
    flex-direction:row;
`

const PlanTitleSpecial = styled.div`
    display:flex;
    flex-direction:row;
    color:green;
    font-weight:bold;
`

const PlanTitlePrice = styled.div`
    display:flex;
    flex-direction:row;
    padding:10px;
    font-size:30px;
`



const ButtonWhite = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: rgb(60, 181, 152);
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;
    

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`



const ButtonClosure = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: #de5233;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 0.4;
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        opacity: 0.9;
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
        opacity: 1.0;
    }


`
