import React, { useState } from 'react';
import styled from 'styled-components';


import * as screens from '../../../screens';

const AccountBilling = (props) => {

    const [billingPage, setBillingPage] = useState('plan');

    return (
       
        <>
            <BenefitTitle>
                
                Account Settings &gt; Billing

                <SelectionButtons>
                    
                    <BillingPage onClick={() => setBillingPage('plan')} active={billingPage === 'plan' ? 'true' : 'false'}>Plan</BillingPage>
                    <BillingPage onClick={() => setBillingPage('payment-methods')} active={billingPage === 'payment-methods' ? 'true' : 'false'}>Payment Methods</BillingPage>
                    <BillingPage onClick={() => setBillingPage('billing-history')} active={billingPage === 'billing-history' ? 'true' : 'false'}>Billing history</BillingPage>
                
                </SelectionButtons>

            </BenefitTitle>

            <ContentsSeperator>


                <PageContainerSection>

                    { billingPage === 'plan' ? (
                        <screens.AccountBillingPlan />
                    ) : null }

                    { billingPage === 'payment-methods' ? (
                        <screens.AccountBillingCards />
                    ) : null }

                    { billingPage === 'billing-history' ? (
                        <screens.AccountBillingBills />
                    ) : null }

                </PageContainerSection>

            </ContentsSeperator>


        </>
    )

}

export default AccountBilling;



const PageContainerSection = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding-top:50px;
    width:100%;
`



const ContentsSeperator = styled.div`
    padding:30px;
    padding-top:20px;
    width:100%;
    height:100%;
`


const BillingPage = styled.div`
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    padding:15px 20px;
    border-radius:10px;
    transition: all 0.5s;
    transform: scale(1.0);
    font-size:14px;
    color:${ props => props.active === 'true' ? '#000000': '#a9a9a9' };
    background: ${ props => props.active === 'true' ? '#bedfe5' : 'transparent'};
    font-weight:bold;
    &:hover {
        background: #bedfe5 !important;
        transform: scale(0.95);
        color:#000000;
    }

`


const SelectionButtons = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:450px;
    border-radius:10px;
    background:#f4f4f4;
    transition:all 0.5s;
    cursor:pointer;

    &:hover > ${BillingPage} {
        color: #000000;
        background: transparent;
    }
`




const BenefitTitle = styled.div`
    font-family: newFontFace, Arial, sans-serif;
    box-sizing:border-box;
    font-size:20px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    text-align:left;
    padding:30px;
    padding-top:10px;
    border-bottom: 1px solid #e5e5e5;
    width:100%;
`