import React from 'react';
import styled, { keyframes } from 'styled-components';

import { icons } from '../../../components';


const CancelButton = ({ action, color }) => {

    return (

        <ButtonSmall onClick={() => action()} backgroundCol={color}>
            <icons.CancelOutlinedStyled />
        </ButtonSmall>

    )

}

export default CancelButton;


const ButtonSmall = styled.button`
    color: #000000;
    box-sizing:border-box;
    font-size:15px;
    padding: 5px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.3s;
    background: ${props => !props.backgroundCol ? '#e5e5e5' : props.backgroundCol};
    border-radius:50px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    margin-right:30px;
    transform: rotate(0deg);

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        transform: rotate(90deg);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
        transform: rotate(0deg);
    }


`
