import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import defaults from '../../constants/defaults';

import Redirections from '../../constants/routes';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../state/actions';

import { database, format } from '../../services';
import * as evaluate from '../../validators';

import * as assets from '../../assets';

import useInput from '../../hooks/useInput';

import { elements, materials, icons, inputs } from '../../components';


const EachDataPoint = ({ product, audiances, index }) => {

    const [generalHelper, setGeneralHelper] = useState([]);
    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    const { value:item, bind:bindItem, reset:resetItem } = useInput(``);
    const [itemHelper, setItemHelper] = useState([]);
    
    const [enrolmentActive, setEnrolmentActive] = useState(product.activeEnrolment);
    const [audiancesEnrolled, setAudiancesEnrolled] = useState(product.audiancesEnrolled);
    const [mappedAudiances, setMappedAudiances] = useState(audiances);

    const { 
        activateProduct,
        linkAudianceToProduct,
        delinkAudianceToProduct
    } = bindActionCreators(actions, useDispatch());

    useEffect(() => {

        console.log('we do here');

        console.log(audiances);
        console.log(audiancesEnrolled);


        const mappedAudiances = audiances.map((val) => {

            const mappedResult = {
                ...val,
                audianceLinked: false
            }

            if (!!audiancesEnrolled) {
                if (audiancesEnrolled.length > 0) {

                    const foundAudiance = audiancesEnrolled.find((other) => other === val._id );
    
                    mappedResult.audianceLinked = !!foundAudiance;
                }
            }
           

            return mappedResult;
            
        })

        setMappedAudiances(mappedAudiances);

    }, [enrolmentActive, audiancesEnrolled])


    const createLinkage = (audianceOnObject) => {

        const formArray = { 
            productID: product._id, 
            audianceID: audianceOnObject
        };

        const helperArray = { 
            productID: setGeneralHelper, 
            audianceID: setGeneralHelper,
        }

        const resetArray = {
            productID: {
                shouldReset: false,
            },
            audianceID: {
                shouldReset: false,
           }
        }

        database.sendForm(
            linkAudianceToProduct,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validLinkingAudianceToProduct,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {
                    setAudiancesEnrolled(payload.productEnrolmentDetails);
                }
            }
        );
    
   }

   const createDelinkage = (audianceOnObject) => {

        const formArray = { 
            productID: product._id, 
            audianceID: audianceOnObject
        };

        const helperArray = { 
            productID: setGeneralHelper, 
            audianceID: setGeneralHelper,
        }

        const resetArray = {
            productID: {
                shouldReset: false,
            },
            audianceID: {
                shouldReset: false,
        }
        }

        database.sendForm(
            delinkAudianceToProduct,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validDelinkingAudianceToProduct,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
                if (success) {
                    setAudiancesEnrolled(payload.productEnrolmentDetails);
                }
            }
        );

    }


    const subscribeToDataPoint = (productID, active, callback) => {

        const formArray = { 
            productID, 
            active
        };

        const helperArray = { 
            productID: setGeneralHelper, 
            active: setGeneralHelper,
        }

        const resetArray = {
            productID: {
                shouldReset: false,
            },
            active: {
               shouldReset: false,
           }
        }

        database.sendForm(
            activateProduct,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validActivateProduct,
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {
                if (success) {
                    callback()
                }
            }
        );
    
   }

    return (
        <EachMember key={index}>
            <EachMemberInner active={false}>
                <HeadingTopSection active={enrolmentActive}>
                    
                    <EachMemberLeft>
                        <NameOfMember>{product.title}</NameOfMember>
                        <SmallText>{`Subscribe a customer list to ${product.frequency.number} email${product.frequency.number > 1 ? 's' : ''} per ${product.frequency.period.toLowerCase()}` }</SmallText>
                    </EachMemberLeft>
                    <inputs.Switch 
                        active={enrolmentActive} 
                        onClick={() => subscribeToDataPoint(product._id, !enrolmentActive, () => {
                            setEnrolmentActive(!enrolmentActive)
                        })}
                    />
                </HeadingTopSection>
                <BottomOptions active={enrolmentActive}>
                    <FieldTitle>Enrolled audiances</FieldTitle>
                    <UpdateFielder>
                        {
                            mappedAudiances.map((aud) => {
                                return (
                                    <AudianceListOuter>
                                        <AudianceList active={!!aud.audianceLinked} onClick={() => !!aud.audianceLinked ? createDelinkage(aud._id) : createLinkage(aud._id)}>
                                            <AudianceName>{aud.name}</AudianceName>
                                            <ButtonSmall active={!!aud.audianceLinked}>
                                                {
                                                    !!aud.audianceLinked ? (
                                                        <icons.CancelOutlinedStyledDark />
                                                    ) : (
                                                        <icons.AddStyledWhite />
                                                    )
                                                }
                                               
                                            </ButtonSmall>
                                        </AudianceList>
                                    </AudianceListOuter>
                                )
                            })
                        }
                    </UpdateFielder>
                </BottomOptions>
            </EachMemberInner>
        </EachMember>
    )
}



const DataPoints = (props) => {
    
    const [pageReady, setpageReady] = useState(true);   

    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    // Inputs
    const { value:search, bind:bindSearch, reset:resetSearch } = useInput('');

    // Helper handlers

    const [generalHelper, setGeneralHelper] = useState([]);

    const [searchHelper, setSearchHelper] = useState([]);

    useEffect(() => {
        setGeneralHelper([])
        setSearchHelper([])
     }, [search])

    const [productList, setProductList] = useState([]);
    const [audianceList, setAudianceList] = useState([]);
    

    useEffect(() => {

        // When page is ready set to:
        // setPageReady(true);

    }, [pageReady])


    useEffect( () => {
        setpageReady(true);
    }, []) 





    const { 
        getProductList,
        getAudiances
    } = bindActionCreators(actions, useDispatch());


    useEffect(() => {
        getProductList({}, (success, payload) => {
            if (success) {
                setProductList(payload.productList);
            }
        });
        getAudiances({}, (success, payload) => {
            if (success) {
                setAudianceList(payload.audianceList)
            }
        });
        setSpinnerSwitch(false)
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <elements.PageContainer 
            pageReady={true}
            pageTitle={defaults.tabbar.GENERAL}
            pageRef={Redirections.ROUTE_LINK_HOME_INTERNAL.partial}
            sticky={false}
            cover={false}
            menu={'access'}
        >
    
                <SectionNext>
          
                        
                        <LeftColumn>
                            
                            
                            <ItemsToShow>
                                <BenefitTitle>Create cycles</BenefitTitle>
                                
                            </ItemsToShow>

    
                            <LeftColumnInner>
                                {
                                    productList.length === 0 && (
                                        <EachMemberNone>
                                            <RoleType>No products exist yet</RoleType>
                                        </EachMemberNone>
                                    )
                                }
                                {
                                    
                                    productList.map((product, index) => {

                                        return (
                                            <EachDataPoint product={product} index={index} key={index} audiances={audianceList} />
                                        )
                                    })
                                }
                                
                            </LeftColumnInner>
                   
                        </LeftColumn>
                    

                </SectionNext>

        </elements.PageContainer>
    )

}

export default DataPoints;



const ItemsToShow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:15px 15px;
    padding-bottom:0px;
`

const UpdateFielder = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    flex-wrap: wrap;
    align-items:center;
    align-content:center;
`

const SmallText = styled.span`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    border-radius:20px;
    background:#e9e9e9;
    dispaly:block;
    padding:7px 10px;
    font-size:13px;
`

const ButtonGoTo = styled.button`
    flex-grow:0;
    flex-shrink:0;
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    width:40px;
    height:40px;
    outline:0;
    margin-left:5px;
    cursor:pointer;
    transition: all 0.5s;
    background: ${props => props.active ? '#3cb598' : '#a9a9a9'};
    opacity:${props => props.active ? '1.0' : '0.5' };
    border-radius:5px;
    border: 1px solid transparent;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    font-weight:bold;
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`

const AudianceName = styled.span`
    font-size: 16px;
    text-align:left;
`


const ButtonSmall = styled.button`
    color: #000000;
    box-sizing:border-box;
    font-size:15px;
    padding: 5px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.3s;
    background: ${ props => props.active ? 'rgb(0, 205, 112)' : '#c5c5c5' };
    border-radius:50px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    margin-left:20px;
    transform: rotate(0deg);

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        transform: rotate(360deg);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
        transform: rotate(0deg);
    }


`


const ProgressBox = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:200px;
    width:100%;
    padding:10px;
`

const InnerProgressBox = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    height:200px;
    width:100%;
    border:1px solid #a9a9a9;
`



const EditButtons = styled.span`
    flex-grow:1;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    padding-left:20px;
`

const SectionName = styled.span`
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    align-content:center;

`

const BottomOptions = styled.div`
    width:100%;
    border-top:1px solid #e5e5e5;
    padding-top:20px;

    ${props => !props.active ? `
        height:0px;
        overflow:hidden;
        padding:0px;
        border-top:none;
    ` : null}

`




const BenefitTitle = styled.div`
    font-family: newFontFace, Arial, sans-serif;
    width:100%;
    height:80px;
    box-sizing:border-box;
    font-size:20px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    text-align:center;
`



const ErrorFieldOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
`


// #4CD964;
const Button = styled.button`
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 25px 0px;
    outline:0;
    margin-top:20px;
    cursor:pointer;
    transition: all 0.5s;
    width:100%;
    background: #3cb598;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    
    font-weight:bold;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:focus {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonForSure = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => props.oftype === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

const AudianceList = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:20px;
    border-radius:5px;
    text-align:center;
    cursor:pointer;
    transition: all 0.3s;
    background: ${props => props.active ? '#9fe9ca' : '#f1f1f1' };
    border:1px solid ${props => props.active ? '#9fe9ca' : '#e5e5e5'};
    width:100%;

    &:hover {
        background: ${props => props.active ? '#5fdaa7' : '#5fdaa7' };

        & > ${ButtonSmall} {
            transform: rotate(360deg);
            background: red;
        }
    }

`

const AudianceListOuter = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:5px;
    width:33.33%;
`


const AnotherButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    white-space:nowrap;
    width: 300px;
    align-items:center;
    justify-content:center;
    text-align:center;

`

const ButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    width:50px;
    align-items:center;
    justify-content:center;
    text-align:center;

`


const ErrorField = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:10px;
    font-size:14px;
    border-radius:50px;
    list-style:none;
    border: 1px dashed #F67280;
    background: #ffecee;
    margin-bottom:20px;


    & > li {
        color: red;
        margin-left:5px;
        color:red;
        text-align:center;
        width:100%;

    }
`

const LeftColumn = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    padding-bottom:20px;
`


const EachMember = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    padding-bottom:20px;
    border-radius:20px;
`

const EachMemberNone = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    padding-bottom:20px;
    border-radius:20px;
`


const EachMemberLeft = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:flex-start;
    align-items: center;
    align-content:center;
    padding-left:10px;
`
const NameOfMember = styled.div`
    font-size: 16px;
    text-align:left;
    font-weight:bold;
    padding-right:20px;
`

const RoleType = styled.div`
    font-size: 16px;
    text-align:left;
`
const GoToSign = styled.div`
    padding-right:5px;
    transition: all 0.3s;
    display:flex;
    justify-content:center;
    align-items: center;
    align-content:center;  
`

const Seperator = styled.div`
    width:100%;
    height: 20px;
`


const EachMemberInner = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content:space-between;
    align-items: center;
    align-content:center;
    border-radius:15px;
    padding:30px;
    background:#ffffff;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
`

const EachMemberInnerAddNew = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items: center;
    align-content:center;
    height:80px;
    border-radius:15px;
    background:rgb(190, 223, 229);
    box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    transition: all 0.3s;
    position:relative;
    top:0px;

    &:hover {
        top:-2px;
        box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 10px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
    }

    &:active {
        top:0px;
        box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 5px 0px, rgba(0, 0, 0, 0.03) 0px 3px 5px 0px;
    }
`

const LeftColumnInner = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    border-radius:30px;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
`

const RightSectionForm = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    justify-content:flex-start;
    align-items:center;
    height:100%;
    padding:30px;
    padding-top:0px;
`

const RightSection = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    justify-content:flex-start;
    align-items:center;
    height:100%;
    padding:30px;
`

const AnotherSection = styled.div`
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    height:100%;
`


const SectionNext = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    height:100%;
    padding:50px;
    
`

const FormField = styled.div`
    padding:10px 0px;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`

const FormFieldRow = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    width:100%;
`


const FormTitles = styled.span`
    font-size:14px;
    font-weight:500;
    padding-bottom:10px;
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing:border-box;

`
const FieldTitle = styled.div`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    font-size:14px;
    padding:10px;
    font-weight:bold;
`


const HeadingTopSection = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    width:100%;
    padding-bottom:20px;

    ${props => !props.active ? `
        padding:0px;
    ` : null}
    
    &:hover {
        & > ${GoToSign} {
            padding-right:20px;
        }
    }
`