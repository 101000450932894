import React from 'react';
import styled from 'styled-components';

const Arrows = ({ direction, color, size }) => {

    return (
        <Container direction={direction} color={color} size={size} />
    );
}

export default Arrows;


const Container = styled.div`
    display:block;
    width: 0; 
    height: 0; 
    border-top: ${props => {
        const sizing = props.direction === 'up' ? '0px' : Number(props.size) + 'px';
        const colorChoice = props.direction === 'down' ? props.color : 'transparent';
        return (sizing + ' solid ' + colorChoice)
    }};
    border-bottom: ${props => {
        const sizing = props.direction === 'down' ? '0px' : Number(props.size) + 'px';
        const colorChoice = props.direction === 'up' ? props.color : 'transparent';
        return (sizing + ' solid ' + colorChoice)
    }};
    border-right: ${props => {
        const sizing = props.direction === 'right' ? '0px' : Number(props.size) + 'px';
        const colorChoice = props.direction === 'left' ? props.color : 'transparent';
        return (sizing + ' solid ' + colorChoice)
    }};
    border-left: ${props => {
        const sizing = props.direction === 'left' ? '0px' : Number(props.size) + 'px';
        const colorChoice = props.direction === 'right' ? props.color : 'transparent';
        return (sizing + ' solid ' + colorChoice)
    }};
`