export const signup = {

    // USERNAME
    name: {
        presence: true,
        length: {
            minimum: 2,
            message: "Must be greater than 2"
        }
    },

    // PASSWORD

    password: {
        presence: true,
        length: {
            minimum: 3,
            message: "Must be greater than 3 characters"
        }
    },

    // EMAIL

    email: {
        presence: true,
        email: true
    }

}



export const validRSVP = {

    passcode: {
        presence: true,
    },

    // EMAIL
    email: {
        presence: false,
        email: true
    },

    // MOBILE
    mobile: {
        presence: false,
    },

    // MOBILE
    guestList: {
        presence: true,
    },

    // MOBILE
    accomodationRequired: {
        presence: true,
    },

    // MOBILE
    arrivingAtAirport: {
        presence: true,
    },


}


export const login = {

    // PASSWORD

    password: {
        presence: { allowEmpty: false },
    },

    // EMAIL

    email: {
        presence: { allowEmpty: false },
        email: true,
    }

}


export const forgot = {

    // EMAIL

    email: {
        presence: true,
        email: true
    }

}



export const reset = {

    // PASSWORD

    password: {
        presence: true
    },

    // PASSWORD

    code: {
        presence: true
    },

    // EMAIL

    email: {
        presence: true,
        email: true
    }

}
