import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import { composeWithDevTools } from "redux-devtools-extension";


export const store = createStore(
    reducers,
    {
      auth: { 
        authenticated: localStorage.getItem('token'),
        name: localStorage.getItem('name'),
        displayPicture: !localStorage.getItem('displayPicture') ? '' : localStorage.getItem('displayPicture')
      }
    },
    composeWithDevTools(
      applyMiddleware(reduxThunk),
    )
  )
  