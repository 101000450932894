import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../state/actions';

import Redirections from '../../constants/routes';

// Accessible only if logged in

const SignOut = (props) => {

    const { signout } = bindActionCreators(actions, useDispatch());
    
    signout(() => {
      
    });

    return (
      <Redirect to={{pathname: Redirections.ROUTE_LINK_HOME.partial, state: { from: props.location }}} />
    )

}

export default SignOut;