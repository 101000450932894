
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../state/actions';


import { Link } from 'react-router-dom';
import { database } from '../../services';
import Redirections from '../../constants/routes';
import * as evaluate from '../../validators';


import defaults from '../../constants/defaults';

import { elements, inputs, materials } from '../../components';


import useInput from '../../hooks/useInput';


const LoginPage = (props) => {

    // General
    const [pageReady, setpageReady] = useState(true);
    const [spinnerSwitch, setSpinnerSwitch] = useState(false);

    // Inputs
    const { value:name, bind:bindName, reset:resetName } = useInput('');
    const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');

    // Helper handlers

    const [generalHelper, setGeneralHelper] = useState([]);

    const [nameHelper, setNameHelper] = useState([]);
    const [emailHelper, setEmailHelper] = useState([]);
    const [passwordHelper, setPasswordHelper] = useState([]);

    useEffect(() => {
        setGeneralHelper([])
     }, [name, email, password])

    useEffect(() => {
        setNameHelper([])
     }, [name])

     useEffect(() => {
        setEmailHelper([])
     }, [email])

     useEffect(() => {
        setPasswordHelper([])
     }, [password])


    // actions

    const { signup } = bindActionCreators(actions, useDispatch());

    const handleSubmit = (e) => {

        e.preventDefault()

        const formArray = { name, email, password }

        const helperArray = { 
            name: setNameHelper, 
            email: setEmailHelper, 
            password: setPasswordHelper
        }

        const resetArray = {
            name: {
                shouldReset: true,
                setterFunction: resetName,
                setterValue: null
            },
            email: {
                shouldReset: true,
                setterFunction: resetEmail,
                setterValue: null
            },
            password: {
                shouldReset: true,
                setterFunction: resetPassword,
                setterValue: null
            }
        }

        database.sendForm(
            signup,
            formArray, 
            helperArray, 
            resetArray,
            evaluate.validSignUp, 
            setSpinnerSwitch,
            setGeneralHelper,
            (success) => {
                if (success) {
                    props.history.push(Redirections.ROUTE_LINK_HOME_INTERNAL.partial)
                }
            }
        );
    
    }


    // Clear errors

    useEffect(() => {
        setpageReady(true)
    }, [])
    
    return (
        <elements.PageContainer 
            pageReady={pageReady}
            pageTitle={defaults.tabbar.GENERAL}
            sticky={true}
            cover={true}
            menu={'none'}
        >
            <ContainerContent onSubmit={handleSubmit}>
            
                <ContentElement>

                    <TopSection>
                        <Title to={`login`} location={'left'} active={'false'}>Log in</Title>
                        <Title to={`join`} location={'right'} active={'true'}>Join</Title>
                    </TopSection>

                    <BottomSection>

                        { generalHelper.length > 0 ? (
                            <ErrorFieldOuter>
                                <ErrorField>
                                    { 
                                        generalHelper.map((item) => {
                                        
                                            
                                            return (<li>{ item }</li>)
                                        
                                        })
                                    }
                                </ErrorField>
                            </ErrorFieldOuter>
                        ) : null }
                    

                        <FormField>
                            <FormTitles>
                                <FieldTitle>First name</FieldTitle>
                            </FormTitles>
                            <inputs.BasicTextInput 
                                title={`Name`}
                                placeholder={``}
                                ready={!spinnerSwitch}
                                type={`text`}
                                bind={bindName} 
                                value={name}
                                additionalStyling={`
                                    background:#f9f9f9;
                                `}
                                autoFocusOnMount={true}
                                symbolRight={null}
                                errorMessage={nameHelper}
                                tabIndex={1}
                                /> 
                        </FormField>
                        

                        <FormField>
                            <FormTitles>
                                <FieldTitle>Email</FieldTitle>
                            </FormTitles>
                            <inputs.BasicTextInput 
                                title={`Email`}
                                placeholder={``}
                                ready={!spinnerSwitch}
                                type={`text`}
                                bind={bindEmail} 
                                value={email}
                                additionalStyling={`
                                    background:#f9f9f9;
                                `}
                                autoFocusOnMount={true}
                                symbolRight={null}
                                errorMessage={emailHelper}
                                tabIndex={2}
                                /> 
                        </FormField>
                        <FormField>
                            <FormTitles>
                                <FieldTitle>Password</FieldTitle>
                            </FormTitles>
                            <inputs.BasicTextInput 
                                title={`Email`}
                                placeholder={``}
                                ready={!spinnerSwitch}
                                type={`password`}
                                bind={bindPassword} 
                                value={password}
                                additionalStyling={`
                                    background:#f9f9f9;
                                `}
                                autoFocusOnMount={true}
                                symbolRight={null}
                                errorMessage={passwordHelper}
                                tabIndex={3}
                                /> 
                        </FormField>

                        <Button tabIndex={4} disabled={spinnerSwitch}>
                            { !spinnerSwitch ? 'Continue' : <materials.Spinner color='#ffffff' size='small'/> }
                        </Button>

                    </BottomSection>
 
                </ContentElement>

            
            </ContainerContent>

        </elements.PageContainer>
    )

}

export default LoginPage;


const FormField = styled.div`
    padding:10px 0px;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`


const ContainerContent = styled.form`
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`

const TopSection = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    flex-wrap: nowrap;
    box-sizing: border-box;
    width:100%;
    position:relative;
    border:none;
    box-sizing:border-box;
    
`



const BottomSection = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    flex-wrap: nowrap;
    box-sizing: border-box;
    padding:25px;
`


const ContentElement = styled.div`
    box-sizing: border-box;
    border:none;
    background: #ffffff;
    border-radius:20px;
    width: 500px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
`


const FormTitles = styled.span`
    font-size:14px;
    font-weight:500;
    padding-bottom:10px;
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing:border-box;

`
const FieldTitle = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
`



// #4CD964;
const Button = styled.button`
    color:#ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 25px 0px;
    outline:0;
    margin-top:20px;
    cursor:pointer;
    transition: all 0.5s;
    width:100%;
    background: #3cb598;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'}
    
    font-weight:bold;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:focus {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

const Title = styled(Link)`
    font-size:20px;
    font-weight:200;
    color: ${props => props.active === 'true' ? '#0066CC' : '#ffffff' };
    background: ${props => props.active === 'true' ? '#ffffff' : '#0066CC'};
    padding:25px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-decoration:none;
    flex:1;
    opacity:0.9;
    transition: all 0.5s;
    ${props => props.location === 'left' ? (
        `
         border-top-left-radius:20px;
         border-bottom:5px solid #0092ff;
        border-right:5px solid #0092ff;
        border-bottom-right-radius:20px;
        `
    ): 
    null }
    ${props => props.location === 'right' ? (
        `
        border-top-right-radius:20px;
        
        `
    ): null }
    box-sizing:border-box;

    &:hover {
        opacity:1;
    }
`



const ErrorFieldOuter = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:100%;
`

const ErrorField = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:10px;
    font-size:14px;
    border-radius:50px;
    list-style:none;
    border: 1px dashed #F67280;
    background: #ffecee;
    margin-bottom:20px;


    & > li {
        color: red;
        margin-left:5px;
        color:red;
        text-align:center;
        width:100%;

    }
`
