import axios from 'axios';
import { JSONResponseToConsole, authHeader, signout } from '../index';

import {
    AUTH_ERROR
}  from '../types';

import { 
    CONNECTION_TO_API_NOT_WORKING,
    INCORRECT_CREDENTIALS
} from '../errors';

import { 
    ROUTE_MESSAGE_SEND, 
    ROUTE_MESSAGE_GETCONTACTS,
    ROUTE_MESSAGE_GETCONVERSATIONS,
    ROUTE_MESSAGE_GETMESSAGES
} from '../routes';

const handleServerErrors = (dispatch, callback, e) => {

    let message = '';

    if (e.response && e.response.status === 401) {
        message = INCORRECT_CREDENTIALS.description;
    } else {
        message = CONNECTION_TO_API_NOT_WORKING.description
    }

    dispatch({ type: AUTH_ERROR, payload: message });
    callback(false, message, true);

}

// ---------------------------
// MESSAGE / GET CONTACTS

export const getContacts = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_MESSAGE_GETCONTACTS, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_MESSAGE_GETCONTACTS} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        console.log(e);
        signout();

    }

}





// ---------------------------
// MESSAGE / GET CONTACTS

export const getConversations = (formProps, callback) => async dispatch => {

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_MESSAGE_GETCONVERSATIONS, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_MESSAGE_GETCONVERSATIONS} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

        signout();

    }

}



// ---------------------------
// MESSAGE / GET CONTACTS

export const getMessages = (formProps, callback) => async dispatch => {

    try {

        console.log(formProps);

        // Pick up the groceries
        const response = await axios.post(ROUTE_MESSAGE_GETMESSAGES, formProps, { headers: authHeader() });

        JSONResponseToConsole(`POST TO ${ROUTE_MESSAGE_GETMESSAGES} endpoint`, response);

        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {

        handleServerErrors(dispatch, callback, e);

        signout();

    }

}





// ---------------------------
// MESSAGE / SEND

export const sendMessage = (formProps, callback) => async dispatch => {

    console.log(formProps);

    try {

        // Pick up the groceries
        const response = await axios.post(ROUTE_MESSAGE_SEND, formProps, { headers: authHeader() });
        JSONResponseToConsole(`POST TO ${ROUTE_MESSAGE_SEND} endpoint`, response);
    
        // Unpack the groceries
        const { success, payload } = response.data;

        callback(success, payload);
        
    } catch (e) {


        console.log(e);

        handleServerErrors(dispatch, callback, e);

        signout();

    }

}
