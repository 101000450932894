import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../state/actions';

import * as evaluate from '../../../validators';

import { Link } from 'react-router-dom';

import Redirections from '../../../constants/routes';

import { icons, inputs, materials } from '../../../components';


const AccountGeneral = (props) => {

    // General
    const [spinnerSwitch, setSpinnerSwitch] = useState(true);

    const [startingName, setStartingName] = useState(``)
    const [startingEmail, setStartingEmail] = useState(``)

    const [makeEdits, setMakeEdits] = useState(false);

    const [displayImage, setDisplayImage] = useState(null);
    const [loadingValue, setLoadingValue] = useState(null);

    const [deleteOption, setDeleteOption] = useState(null);


    // actions

    const { 
        getAccountDetails,
        updateAccount,
        uploadImage
    } = bindActionCreators(actions, useDispatch());

    const uploadImageToServer = () => {

        if (!!loadingValue) {

            const formData = new FormData();
            formData.append('image', loadingValue);

            uploadImage(formData, (success, response) => {
                if (success) {
                    console.log(response);
                }
            });
        }
       
    }


    useEffect(() => {

        getAccountDetails({}, (success, response) => {
            if (success) {
                const { name, email } = response;
                
                setStartingName(name);
                setStartingEmail(email);

                setSpinnerSwitch(false);

            }
        })

        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
       
            <>
                <BenefitTitle>Account Settings &gt; General</BenefitTitle>

                <ContentsSeperator>

                    <FormField>
                        <FormTitles>
                            <FieldTitle>
                                Display Picture
                            </FieldTitle>
                        </FormTitles>
                
                        <TransitionToColumn>


                            <FieldValue>
                                {
                                    spinnerSwitch ? (
                                        <AlignLeft>
                                            <materials.Spinner color='#a9a9a9' size='small'/>
                                        </AlignLeft>
                                    ) : (
                                        <FilterValues>
                                            { makeEdits ? (
                                                !deleteOption ? (
                                                    <ButtonSmall type={'edit'} onClick={() => setDeleteOption(true)} backgroundCol={'#FF7F7F'}>
                                                        <icons.CancelOutlinedStyled />
                                                    </ButtonSmall>
                                                ) : (
                                                    <ButtonSmall type={'edit'} onClick={() => setDeleteOption(false)} backgroundCol={'#e5e5e5'}>
                                                        <icons.ArrowBackStyled />
                                                    </ButtonSmall>
                                                )
                                            ) : null }

                                            <inputs.OneImage 
                                                size={'small'}
                                                imageValue={displayImage}
                                                setImageValue={setDisplayImage}
                                                setLoadingValue={setLoadingValue}
                                            />
                                        </FilterValues>
                                    )
                                }
                                

                                
                            </FieldValue>

                            <EditButtons>

                                {
                                    !makeEdits ? (
                                        <Button type={'edit'} onClick={() => setMakeEdits(true)}>
                                            <ButtonText type={'edit'}>Edit</ButtonText>
                                        </Button>
                                    ) : (
                                        <>
                                            <Button type={'edit'} onClick={() => setMakeEdits(false)}>
                                                <ButtonText type={'edit'}>Cancel</ButtonText>
                                            </Button>
                                            
                                           { deleteOption ? (
                                                <ButtonClosure type={'submit'} onClick={() => {}}>
                                                    <ButtonTextNew type={'save'}>Confirm remove</ButtonTextNew>
                                                </ButtonClosure>
                                            ) : (
                                                <Button type={'submit'} onClick={uploadImageToServer}>
                                                    <ButtonTextNew type={'save'}>Confirm picture</ButtonTextNew>
                                                </Button>
                                            )
                                           }
                                        </>
                                    )
                                }
                                

                               

                            </EditButtons>

                        </TransitionToColumn>

                        
                        
                    </FormField> 

                    <Seperator />

                    <inputs.SendAccountItem 
                        title={`First name`}
                        formName={`name`} 
                        startingValue={startingName}
                        isLoading={spinnerSwitch}
                        action={updateAccount}
                        validationConstraint={evaluate.validUpdateAccount}
                        tabIndex={1}
                    />

                    <Seperator />

                    <inputs.SendAccountItem 
                        title={`Email`}
                        formName={`email`} 
                        startingValue={startingEmail}
                        isLoading={spinnerSwitch}
                        action={updateAccount}
                        validationConstraint={evaluate.validUpdateAccount}
                        tabIndex={2}
                    />

                    <Seperator />

                    <FormField>
                        <FormTitles>
                            <FieldTitle>
                                Password
                            </FieldTitle>
                            {
                                // Provide saved field setting
                            }
                        </FormTitles>
                
                        <TransitionToColumn>

                
                            <FieldValue>
                                {
                                    spinnerSwitch ? (
                                        <AlignLeft>
                                            <materials.Spinner color='#a9a9a9' size='small'/>
                                        </AlignLeft>
                                    ) : (
                                        <>********************</>
                                    )
                                }
                                

                                
                            </FieldValue>

                            <EditButtons>

                                <ButtonLink to={Redirections.ROUTE_LINK_RESET.partial} type={'save'}>
                                    <ButtonTextReset type={'save'}>Reset password</ButtonTextReset>
                                </ButtonLink>


                            </EditButtons>

                        </TransitionToColumn>

                        
                        
                    </FormField> 



                </ContentsSeperator>



            </>
                             
    )

}

export default AccountGeneral;



const FilterValues = styled.div`
    display:flex;
    flex-direction:row;
`


const TransitionToColumn = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;


`

const Seperator = styled.div`
    width:100%;
    height: 20px;
`



const AlignLeft = styled.span`
    padding-left:7px;
`



const ContentsSeperator = styled.div`
    padding:30px;
    padding-top:20px;
    width:100%;
    height:100%;
`

const Button = styled.button`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: ${props => props.type === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`



const ButtonSmall = styled.button`
    color: #000000;
    box-sizing:border-box;
    font-size:15px;
    padding: 5px;
    height:30px;
    width:30px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    transition: all 0.5s;
    background: ${props => !props.backgroundCol ? '#e5e5e5' : props.backgroundCol};
    border-radius:50px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    margin-right:30px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`




const ButtonClosure = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: #de5233;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 0.4;
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        opacity: 0.9;
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
        opacity: 1.0;
    }


`



const ButtonLink = styled(Link)`
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    text-decoration:none;
    background: ${props => props.type === 'edit' ? '#e5e5e5' : '#3cb598'};
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: ${props => props.disabled ? '0.3' : '1.0'};
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`


const ButtonText = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    width:50px;
    align-items:center;
    justify-content:center;
    text-align:center;

`

const ButtonTextNew = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    width:150px;
    align-items:center;
    justify-content:center;
    text-align:center;

`


const ButtonTextReset = styled.span`
    display:flex;
    color: ${props => props.type === 'edit' ? '#000000' : '#ffffff'};
    font-size:15px;
    align-items:center;
    justify-content:center;
    text-align:center;
    width:150px;

`


const FormField = styled.div`
    padding:10px 0px;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
`


const FormTitles = styled.span`
    font-size:14px;
    font-weight:500;
    padding-bottom:10px;
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing:border-box;

`

const FieldTitle = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    font-weight:bold;
`


const FieldValue = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    width:100%;
`


const EditButtons = styled.span`
    flex-grow:1;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
    padding-left:20px;
`


const BenefitTitle = styled.div`
    font-family: newFontFace, Arial, sans-serif;
    box-sizing:border-box;
    font-size:20px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    align-content:center;
    text-align:left;
    padding:30px;
    padding-top:10px;
    border-bottom: 1px solid #e5e5e5;
    width:100%;
`





