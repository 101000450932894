import React from 'react';
import styled from 'styled-components';

import { materials } from '../../../components';


const PageSpinner = (props) => {

    return (

        <SpinnerBackground>

            <materials.Spinner color={props.color} size={props.size} />
    
        </SpinnerBackground>

    )

}

export default PageSpinner;


const SpinnerBackground = styled.div`
    display:flex;
    flex-direction:column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width:100%;
    height:100%;
    background: rgb(0,146,255);
    background: linear-gradient(135deg, rgba(0,146,255,1) 0%, rgba(0,212,255,1) 100%);
`