import React, { useState } from 'react';
import styled from 'styled-components';


const Switch = ({ active, onClick }) => {

    const [switchOn, setSwitchOn] = useState(!active ? false : active);

    return (

        <Container onClick={() => {
            setSwitchOn(current => !current)
            onClick()
        }} on={switchOn ? 'YES' : 'NO'}>
            <Circle on={switchOn ? 'YES' : 'NO'} />
            <SwitchTitle on={switchOn ? 'YES' : 'NO'}>
                {switchOn ? 'YES' : 'NO'}
            </SwitchTitle>
        </Container>

    )

}

export default Switch;



const Container = styled.div`
    width:70px;
    height:30px;
    background:${props => props.on === 'YES' ? 'rgb(0, 205, 112)' : '#ff8a00'};
    border-radius:100px;
    border:1px solid #f5f5f5;
    position:relative;
    transition: all 0.4s;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-shrink:0;
    flex-grow:0;
`

const Circle = styled.div`
    width:30px;
    height:30px;
    position:absolute;
    top:-1px;
    left:-1px;
    border-radius:100px;
    background:#ffffff;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 10px 0px, rgba(0, 0, 0, 0.12) 0px 3px 10px 0px;
    transition: all 0.4s;
    transform: translateX(${props => props.on === 'YES' ? '40px' : '0px'});
`

const SwitchTitle = styled.span`
    color:#ffffff;
    position:absolute;
    font-size:13px;
    font-weight:600;
    left: ${props => props.on === 'YES' ? '10px' : 'auto'};
    right: ${props => props.on === 'YES' ? 'auto' : '10px'};

`